import React from 'react';
import { Link } from 'react-router-dom';
import { FaInstagram, FaYoutube } from 'react-icons/fa';
import './Footer.css';


import iyzico from '../assets/iyzico.png';

import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope } from 'react-icons/fa'; // React Icons kütüphanesi




const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* 1. Sütun: Logo ve Slogan */}
        <div className="footer-column">
          <div className="footer-logo">
            <button
              className="footer-logo-button"
              onClick={() => window.location.href = '/'} // Ana sayfaya yönlendirme
              aria-label="Go to Home Page"
            >
              hocabul.net
            </button>
            <p className="footer-slogan">"Eğitimde Yenilikçi Çözümler"</p>
          </div>
        </div>

        {/* 2. Sütun: Sayfa Linkleri */}
        <div className="footer-column">
          <ul className="footer-list">
           <ul className="footer-list">
  <li><a href="https://hocabul.net/terms">Bireysel Kullanıcı Sözleşmesi</a></li>
  <li><a href="https://hocabul.net/privacy">Gizlilik Sözleşmesi</a></li>
  <li><a href="https://hocabul.net/delivery-and-refund">Teslimat ve İade Şartları</a></li>
  <li><a href="https://hocabul.net/sales-contract">Mesafeli Satış Sözleşmesi</a></li>
  <li><a href="https://hocabul.net/about">Hakkımızda</a></li>
  <li><a href="https://hocabul.net/how-it-works">Nasıl Kullanılır</a></li>
</ul>

			
          </ul>
        </div>

       {/* 3. Sütun: Adres ve İletişim Bilgileri */}
        <div className="footer-column">
         
          <ul className="footer-contact">
            <li>
              <FaMapMarkerAlt className="contact-icon" />
              75. Yıl OSB, Bilim Caddesi No: 5/107, Odunpazarı, Eskişehir
            </li>
            <li>
              <FaPhoneAlt className="contact-icon" />
              +90 850 305 75 62
            </li>
            <li>
              <FaEnvelope className="contact-icon" />
              info@tungasoft.com
            </li>
          </ul>
        </div>

        {/* 4. Sütun: Sosyal Medya */}
        <div className="footer-column">
         
          <div className="footer-social"> 
            <a
              href="https://instagram.com/hocabulnet"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <FaInstagram className="social-icon" />
            </a>
            <a
              href="https://youtube.com/osmanalagoz83"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
            >
              <FaYoutube className="social-icon" />
            </a>
          </div>
		  
		   
		  
        </div>
		
		
      </div>

     {/* Ödeme Altyapısı Logoları */}
      <div className="footer-payments">  
        <img src={iyzico} alt="Iyzico" className="payment-logo" />
      </div>

      <p className="footer-text">&copy; 2024 hocabul.net</p>
    </footer>
  );
};

export default Footer;
