import React, { useState, useEffect } from 'react';
import './Team.css';
import TeacherCard from './TeacherCard';
import { useNavigate } from 'react-router-dom';

const categoryMap = {
  İlkokul: 'ilkokul',
  Ortaokul: 'ortaokul',
  Lise: 'lise',
  Üniversite: 'universite',
  'Yabancı Dil': 'yabanci-dil',
  Sınavlar: 'sinavlar',
};

const categories = Object.keys(categoryMap);

function Team() {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]);
  const [location, setLocation] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [expertise, setExpertise] = useState('');
  const [format, setFormat] = useState('');
  const [teacherCounts, setTeacherCounts] = useState({});

  const navigate = useNavigate();

  const handleCardClick = (teacherEmail) => {
    navigate(`/cv/${teacherEmail}`);
  };

  // Fetch teacher data for the selected category
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const categoryKey = categoryMap[selectedCategory];
        const response = await fetch(
          `https://hocabul.net/api/category/${categoryKey}`
        );
        if (!response.ok) {
          throw new Error(`Failed to fetch data for category ${selectedCategory}`);
        }
        const data = await response.json();
        setTeachers(data);
        setFilteredTeachers(data); // Set filtered teachers initially
      } catch (error) {
        console.error('Error fetching teacher data:', error);
        setTeachers([]);
        setFilteredTeachers([]);
      }
    };

    fetchTeachers();
  }, [selectedCategory]);

  // Fetch teacher counts for all categories
  useEffect(() => {
    const fetchTeacherCounts = async () => {
      try {
        const response = await fetch('https://hocabul.net/api/category-counts');
        if (!response.ok) {
          throw new Error('Failed to fetch category counts');
        }

        const counts = await response.json();
        const formattedCounts = categories.reduce((acc, category) => {
          const key = categoryMap[category];
          acc[category] = counts[key] || 0;
          return acc;
        }, {});

        setTeacherCounts(formattedCounts);
      } catch (error) {
        console.error('Error fetching teacher counts:', error);
      }
    };

    fetchTeacherCounts();
  }, []);

  // Filter teachers based on user input
  useEffect(() => {
    const filterTeachers = () => {
      let filtered = [...teachers];

      if (location) {
        filtered = filtered.filter((teacher) => teacher.yer === location);
      }

      if (expertise) {
        filtered = filtered.filter((teacher) =>
          teacher.subject.toLowerCase().includes(expertise.toLowerCase())
        );
      }

      if (format) {
        if (format === 'Hibrit') {
          filtered = filtered.filter(
            (teacher) =>
              teacher.format === 'Yüzyüze' ||
              teacher.format === 'Online' ||
              teacher.format === 'Yüzyüze veya Online'
          );
        } else {
          filtered = filtered.filter((teacher) => teacher.format === format);
        }
      }

      if (minPrice) {
        filtered = filtered.filter((teacher) => parseFloat(teacher.fee) >= parseFloat(minPrice));
      }

      if (maxPrice) {
        filtered = filtered.filter((teacher) => parseFloat(teacher.fee) <= parseFloat(maxPrice));
      }

      setFilteredTeachers(filtered);
    };

    filterTeachers();
  }, [location, minPrice, maxPrice, expertise, format, teachers]);

  const expertiseOptions = Array.from(new Set(teachers.map((teacher) => teacher.subject)));

  return (
    <div className="team-page">
      <div className="filter-and-category">
        {/* Categories Card */}
        <div className="categories-card">
          <h3>Kategoriler</h3>
          <ul className="category-list">
            {categories.map((category) => (
              <li
                key={category}
                className={`category-item ${category === selectedCategory ? 'active' : ''}`}
                onClick={() => setSelectedCategory(category)}
              >
                {category} ({teacherCounts[category] || 0})
              </li>
            ))}
          </ul>
        </div>

        {/* Filters Section */}
        <div className="filter-container">
          <div className="filter-item">
            <label htmlFor="location">Şehir:</label>
            <select id="location" value={location} onChange={(e) => setLocation(e.target.value)}>
              <option value="">Tüm Şehirler</option>
              <option value="İstanbul Avrupa">İstanbul Avrupa</option>
<option value="İstanbul Anadolu">İstanbul Anadolu</option>
<option value="Adana">Adana</option>
<option value="Adıyaman">Adıyaman</option>
<option value="Afyonkarahisar">Afyonkarahisar</option>
<option value="Ağrı">Ağrı</option>
<option value="Aksaray">Aksaray</option>
<option value="Amasya">Amasya</option>
<option value="Ankara">Ankara</option>
<option value="Antalya">Antalya</option>
<option value="Ardahan">Ardahan</option>
<option value="Artvin">Artvin</option>
<option value="Aydın">Aydın</option>
<option value="Balıkesir">Balıkesir</option>
<option value="Bartın">Bartın</option>
<option value="Batman">Batman</option>
<option value="Bayburt">Bayburt</option>
<option value="Bilecik">Bilecik</option>
<option value="Bingöl">Bingöl</option>
<option value="Bitlis">Bitlis</option>
<option value="Bolu">Bolu</option>
<option value="Burdur">Burdur</option>
<option value="Bursa">Bursa</option>
<option value="Çanakkale">Çanakkale</option>
<option value="Çankırı">Çankırı</option>
<option value="Çorum">Çorum</option>
<option value="Denizli">Denizli</option>
<option value="Diyarbakır">Diyarbakır</option>
<option value="Düzce">Düzce</option>
<option value="Edirne">Edirne</option>
<option value="Elazığ">Elazığ</option>
<option value="Erzincan">Erzincan</option>
<option value="Erzurum">Erzurum</option>
<option value="Eskişehir">Eskişehir</option>
<option value="Gaziantep">Gaziantep</option>
<option value="Giresun">Giresun</option>
<option value="Gümüşhane">Gümüşhane</option>
<option value="Hakkari">Hakkari</option>
<option value="Hatay">Hatay</option>
<option value="Iğdır">Iğdır</option>
<option value="Isparta">Isparta</option>
<option value="İzmir">İzmir</option>
<option value="Kahramanmaraş">Kahramanmaraş</option>
<option value="Karabük">Karabük</option>
<option value="Karaman">Karaman</option>
<option value="Kars">Kars</option>
<option value="Kastamonu">Kastamonu</option>
<option value="Kayseri">Kayseri</option>
<option value="Kırıkkale">Kırıkkale</option>
<option value="Kırklareli">Kırklareli</option>
<option value="Kırşehir">Kırşehir</option>
<option value="Kilis">Kilis</option>
<option value="Kocaeli">Kocaeli</option>
<option value="Konya">Konya</option>
<option value="Kütahya">Kütahya</option>
<option value="Malatya">Malatya</option>
<option value="Manisa">Manisa</option>
<option value="Mardin">Mardin</option>
<option value="Mersin">Mersin</option>
<option value="Muğla">Muğla</option>
<option value="Muş">Muş</option>
<option value="Nevşehir">Nevşehir</option>
<option value="Niğde">Niğde</option>
<option value="Ordu">Ordu</option>
<option value="Osmaniye">Osmaniye</option>
<option value="Rize">Rize</option>
<option value="Sakarya">Sakarya</option>
<option value="Samsun">Samsun</option>
<option value="Siirt">Siirt</option>
<option value="Sinop">Sinop</option>
<option value="Sivas">Sivas</option>
<option value="Şanlıurfa">Şanlıurfa</option>
<option value="Şırnak">Şırnak</option>
<option value="Tekirdağ">Tekirdağ</option>
<option value="Tokat">Tokat</option>
<option value="Trabzon">Trabzon</option>
<option value="Tunceli">Tunceli</option>
<option value="Uşak">Uşak</option>
<option value="Van">Van</option>
<option value="Yalova">Yalova</option>
<option value="Yozgat">Yozgat</option>
<option value="Zonguldak">Zonguldak</option>

            </select>
          </div>

          {/* Min and Max Price Filter */}
          <div className="filter-item">
            <label>Fiyat:</label>
            <div className="price-inputs">
              <input
                type="number"
                placeholder="min"
                value={minPrice}
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <input
                type="number"
                placeholder="max"
                value={maxPrice}
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </div>
          </div>

          <div className="filter-item">
            <label htmlFor="expertise">Uzmanlık:</label>
            <select id="expertise" value={expertise} onChange={(e) => setExpertise(e.target.value)}>
              <option value="">Tüm Uzmanlıklar</option>
              {expertiseOptions.map((exp, index) => (
                <option key={index} value={exp}>
                  {exp}
                </option>
              ))}
            </select>
          </div>

          <div className="filter-item">
            <label htmlFor="format">Format:</label>
            <select id="format" value={format} onChange={(e) => setFormat(e.target.value)}>
              <option value="">Tüm Formatlar</option>
              <option value="Yüzyüze">Yüzyüze</option>
              <option value="Online">Online</option>
              <option value="Hibrit">Hibrit</option>
            </select>
          </div>
        </div>
      </div>

      {/* Teacher Cards */}
      <div className="teacher-cards">
        <h2>{selectedCategory} Öğretmenleri</h2>
        <div className="cards-container">
          {filteredTeachers.length > 0 ? (
            filteredTeachers.map((teacher) => (
              <TeacherCard key={teacher.email} teacher={teacher} onCardClick={handleCardClick} />
            ))
          ) : (
            <p>Bu kategoride öğretmen bulunamadı.</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Team;
