import React from 'react';
import styled from 'styled-components';
import { FaLinkedin, FaYoutube, FaInstagram, FaGlobe } from 'react-icons/fa';

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
`;

const IconLink = styled.a`
  color: #555;
  font-size: 1.5em;
  transition: color 0.3s;

  &:hover {
    color: #000;
  }
`;

function SocialIcons({ linkedin, youtube, instagram, web }) {
  return (
    <IconsContainer>
      {linkedin && (
        <IconLink href={linkedin} target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </IconLink>
      )}
      {youtube && (
        <IconLink href={youtube} target="_blank" rel="noopener noreferrer">
          <FaYoutube />
        </IconLink>
      )}
      {instagram && (
        <IconLink href={instagram} target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </IconLink>
      )}
      {web && (
        <IconLink href={web} target="_blank" rel="noopener noreferrer">
          <FaGlobe />
        </IconLink>
      )}
    </IconsContainer>
  );
}

export default SocialIcons;
