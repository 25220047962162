import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import ReCAPTCHA from "react-google-recaptcha"; // ReCAPTCHA Kütüphanesi
import { AuthContext } from '../context/AuthContext'; // AuthContext'i import et

const FormContainer = styled.div`
  background-color: #34445b;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 300px;
  max-width: 500px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
`;

const FormHeading = styled.h2`
  text-align: center;
  color: #ffffff;
  margin-bottom: 20px;
`;

const InputGroup = styled.div`
  margin-bottom: 15px;
   width: 260px;
   padding: 10px;
   
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  font-size: 1em;
  background-color: #FFF;
  color: #333;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  border: 1px solid #555;
  border-radius: 5px;
  font-size: 1em;
  background-color: #FFF;
  color: #333;
  resize: none;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  margin-top: 15px;
  display: block;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }
`;



function AppointmentForm({ teacher, closeModal }) {
  const { authState } = useContext(AuthContext); // AuthContext'ten öğretmen e-posta bilgisi al
  const teacherEmail = authState.user?.email || ''; // Öğretmen e-posta bilgisi
  const [formData, setFormData] = useState({
    client_name: '',
    client_phone: '',
    client_studentName: '',
    client_subject: teacher.subject,
    client_grade: '',
    client_city: '',
    client_schoolType: '',
    client_goals: '',
    client_additionalQuestions: '',
    teacher_email: teacherEmail, // Öğretmen e-posta bilgisi form alanına ekleniyor
  });
  
  
const [recaptchaToken, setRecaptchaToken] = useState(null); // ReCAPTCHA Token

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

 const handleRecaptchaChange = (token) => {
  setRecaptchaToken(token);
  console.log('Generated reCAPTCHA token:', token); // Log the token
};

  
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      alert("Lütfen reCAPTCHA doğrulamasını tamamlayın.");
      return;
    }

    try {
      const response = await fetch('https://hocabul.net/api/send-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ...formData, recaptchaToken, teacherEmail: teacher.email }),
      });

      if (response.ok) {
        alert('Randevu talebiniz başarıyla gönderildi!');
        closeModal();
      } else {
        alert('Bir hata oluştu. Lütfen tekrar deneyin.');
      }
    } catch (err) {
      console.error('Error submitting form:', err);
      alert('Sunucuya bağlanırken bir sorun oluştu.');
    }
  };




  return (
    <FormContainer>
     
      <form onSubmit={handleSubmit}>
        <InputGroup>
          <Label>İsim Soyisim</Label>
          <Input
            type="text"
            name="client_name"
            value={formData.client_name}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Telefon</Label>
          <Input
            type="text"
            name="client_phone"
            value={formData.client_phone}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Email</Label>
          <Input
            type="email"
            name="client_email"
            value={formData.client_email}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Kim Ders Alacak</Label>
          <Input
            type="text"
            name="client_studentName"
            value={formData.client_studentName}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Hangi Ders</Label>
          <Input
            type="text"
            name="client_subject"
            value={formData.client_subject}
            onChange={handleChange}
            required
            disabled
          />
        </InputGroup>
        <InputGroup>
          <Label>Öğrenci Kaça Gidiyor</Label>
          <Input
            type="text"
            name="client_grade"
            value={formData.client_grade}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Yaşadığınız İl</Label>
          <Input
            type="text"
            name="client_city"
            value={formData.client_city}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Öğrenci Hangi Okul Türüne Gidiyor</Label>
          <Input
            type="text"
            name="client_schoolType"
            value={formData.client_schoolType}
            onChange={handleChange}
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Beklentiniz veya Hedefiniz</Label>
          <TextArea
            name="client_goals"
            value={formData.client_goals}
            onChange={handleChange}
            rows="4"
            required
          />
        </InputGroup>
        <InputGroup>
          <Label>Sormak İstediğiniz Ekstra Bir Şey</Label>
          <TextArea
            name="client_additionalQuestions"
            value={formData.client_additionalQuestions}
            onChange={handleChange}
            rows="4"
          />
        </InputGroup>
		
		 <ReCAPTCHA
          sitekey="6LcWkZEqAAAAAF3uQ3uCiRiW5ZnCKvHk4pp7ltbF" // Google reCAPTCHA'dan aldığınız site key
          onChange={handleRecaptchaChange}
        />
        <SubmitButton type="submit">Gönder</SubmitButton>
      </form>
    </FormContainer>
  );
}

export default AppointmentForm;
