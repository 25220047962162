import React, { useState, useEffect, useContext } from 'react';
import './BasicCVApplication.css';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i ekle

function BasicCVApplication() {
  const { authState } = useContext(AuthContext); // AuthContext'ten kullanıcı bilgilerini al
  const [formData, setFormData] = useState({
    email: authState.user?.email || '', // E-posta bilgisi AuthContext'ten alınır
    about: '',
    detailedAddress: '',
    highSchool: '',
    master: '',
    PhD: '',
    categories: [],
    phone: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const categoriesOptions = [
    'İlkokul', 'Ortaokul', 'Lise', 'LGS', 'TYT', 'AYT', 'DGS', 'ALES', 'KPSS', 'ÖABT',
  ];

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setFormData((prev) => ({
        ...prev,
        categories: checked
          ? [...prev.categories, value] // Add selected category
          : prev.categories.filter((category) => category !== value), // Remove unselected category
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Form validation
  const validateForm = () => {
    const phoneRegex = /^[0-9]{10,15}$/; // Example phone validation regex
    if (!formData.email || !formData.detailedAddress || !formData.about || !formData.highSchool || !formData.phone) {
      alert('Lütfen tüm zorunlu alanları doldurun.');
      return false;
    }
    if (!phoneRegex.test(formData.phone)) {
      alert('Geçerli bir telefon numarası giriniz.');
      return false;
    }
    if (formData.categories.length === 0) {
      alert('Lütfen en az bir kategori seçiniz.');
      return false;
    }
    return true;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return; // Stop submission if validation fails

    const cvData = {
      email: formData.email,
      about: formData.about,
      detailedAddress: formData.detailedAddress,
      highSchool: formData.highSchool,
      master: formData.master || null,
      PhD: formData.PhD || null,
      categories: formData.categories,
      phone: formData.phone,
    };

    console.log('Submitting Data:', cvData); // Debugging log

    try {
      const response = await fetch('https://hocabul.net/api/basic-cv', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(cvData),
      });

      if (response.ok) {
        setSubmitted(true);
      } else {
        const error = await response.json();
        alert(`Başvuru gönderilemedi: ${error.message}`);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Sunucuya bağlanırken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <div className="basic-cv-form">
      <h2>Temel CV Başvurusu</h2>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          {/* Email */}
          <label htmlFor="email">E-posta Adresiniz:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            readOnly // E-posta alanını yalnızca okunabilir yap
            required
          />

          {/* Phone */}
          <label htmlFor="phone">Telefon numaranız:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />

          {/* Detailed Address */}
          <label htmlFor="detailedAddress">Adresiniz (İl ve İlçe):</label>
          <input
            type="text"
            id="detailedAddress"
            name="detailedAddress"
            value={formData.detailedAddress}
            onChange={handleChange}
            required
          />

          {/* About Section */}
          <label htmlFor="about">Hakkınızda:</label>
          <textarea
            id="about"
            name="about"
            value={formData.about}
            onChange={handleChange}
            rows="4"
            required
          ></textarea>

          {/* High School */}
          <label htmlFor="highSchool">Mezun Olduğunuz Lise:</label>
          <input
            type="text"
            id="highSchool"
            name="highSchool"
            value={formData.highSchool}
            onChange={handleChange}
            required
          />

          {/* Master Degree */}
          <label htmlFor="master">Yüksek Lisans Derecesi (Opsiyonel):</label>
          <input
            type="text"
            id="master"
            name="master"
            value={formData.master}
            onChange={handleChange}
          />

          {/* PhD Degree */}
          <label htmlFor="PhD">Doktora Derecesi (Opsiyonel):</label>
          <input
            type="text"
            id="PhD"
            name="PhD"
            value={formData.PhD}
            onChange={handleChange}
          />

          {/* Other Categories */}
          <label htmlFor="categories">Çalıştığınız Diğer Kategoriler:</label>
          <div className="multi-select">
            {categoriesOptions.map((category) => (
              <label key={category} className="checkbox-label">
                <input
                  type="checkbox"
                  name="categories"
                  value={category}
                  checked={formData.categories.includes(category)}
                  onChange={handleChange}
                />
                {category}
              </label>
            ))}
          </div>

          <button type="submit">Başvuruyu Gönder</button>
        </form>
      ) : (
        <div className="submission-message">
          <p>Başvurunuz başarıyla alınmıştır. Teşekkür ederiz!</p>
        </div>
      )}
    </div>
  );
}

export default BasicCVApplication;
