import React from "react";
import "./Terms.css"; // Aynı CSS dosyasını kullanıyoruz

const Privacy = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Kişisel Verilerin Korunması ve İşlenmesi Hakkında Aydınlatma Metni</h1>
        <p>
          <strong>Son Güncelleme Tarihi:</strong> [27/11/2024]
        </p>
      </header>

      <section className="terms-content">
        <h2>1. Amaç ve Kapsam</h2>
        <p>
          Bu Kişisel Verilerin Korunması ve İşlenmesi Hakkında Aydınlatma Metni
          (“Aydınlatma Metni”), <strong>Tungasoft Yazılım Eğitim ve Danışmanlık
          Anonim Şirketi</strong> (“Şirket”) tarafından yönetilen ve hizmete sunulan{" "}
          <strong>hocabul.net</strong> platformunun (“Platform”) kullanımı sırasında
          elde edilen kişisel verilerin işlenme süreçleri hakkında bilgi vermek
          amacıyla hazırlanmıştır.
        </p>
        <p>
          Platform kullanıcılarının kişisel verileri, 6698 sayılı{" "}
          <strong>Kişisel Verilerin Korunması Kanunu</strong> (“KVKK”) ve ilgili
          diğer mevzuata uygun olarak işlenmektedir. Kullanıcıların verilerinin
          toplanma yöntemleri, işlenme amaçları, hukuki dayanakları ve haklarına
          ilişkin şeffaf bir şekilde bilgilendirilmesi amaçlanmaktadır.
        </p>

        <h2>2. Veri Sorumlusu</h2>
        <p>
          KVKK’nın 10. maddesi kapsamında, kişisel verileriniz; veri sorumlusu
          sıfatıyla <strong>Tungasoft Yazılım Eğitim ve Danışmanlık Anonim
          Şirketi</strong> tarafından işlenmektedir. Veri sorumlusuna aşağıdaki
          iletişim bilgileri üzerinden ulaşabilirsiniz:
        </p>
        <ul>
          <li>
            <strong>Adres:</strong> 75. yıl osb bilim caddesi no: 5/107 odunpazarı, Eskişehir
          </li>
          <li>
            <strong>E-posta:</strong> info@tungasoft.com
          </li>
          <li>
            <strong>Telefon:</strong> +90 850 305 75 62 
          </li>
        </ul>

        <h2>3. Toplanan Kişisel Veriler</h2>
        <p>
          Platforma üye olan ve hizmetlerden faydalanan kullanıcıların aşağıdaki
          kategorilerde kişisel verileri toplanabilir:
        </p>
        <ul>
          <li>
            <strong>Kimlik ve İletişim Bilgileri:</strong> Ad, soyad, telefon
            numarası, e-posta adresi, TC kimlik numarası, adres, kullanıcı adı gibi
            bilgiler.
          </li>
          <li>
            <strong>Demografik Bilgiler:</strong> Doğum tarihi, cinsiyet, medeni
            durum, eğitim durumu, meslek, ilgi alanları.
          </li>
          <li>
            <strong>Kullanım Verileri:</strong> Platformun kullanım sıklığı,
            oturum geçmişi, ziyaret edilen sayfalar, işlem hareketleri, hata
            raporları.
          </li>
          <li>
            <strong>Ödeme Bilgileri:</strong> Fatura bilgileri, ödeme tutarı, ödeme
            yöntemi, işlem tarihi gibi finansal veriler.
          </li>
          <li>
            <strong>Konum Verileri:</strong> Kullanıcıların mobil cihazları veya IP
            adresi üzerinden sağladıkları hassas veya yaklaşık konum bilgileri.
          </li>
        </ul>

        <h2>4. Kişisel Verilerin İşlenme Amaçları</h2>
        <p>
          Toplanan kişisel veriler, KVKK’nın 5. ve 6. maddelerine uygun olarak
          aşağıdaki amaçlarla işlenmektedir:
        </p>
        <ul>
          <li>Platform üyelik işlemlerinin gerçekleştirilmesi.</li>
          <li>Özel ders taleplerinin organize edilmesi.</li>
          <li>Kullanıcı ile iletişim sağlanması ve bilgilendirme yapılması.</li>
          <li>Hizmetlerin iyileştirilmesi ve kullanıcı deneyiminin artırılması.</li>
          <li>Yasal yükümlülüklerin yerine getirilmesi.</li>
          <li>Dolandırıcılık ve kötüye kullanımın önlenmesi.</li>
          <li>Faturalandırma ve ödeme işlemlerinin gerçekleştirilmesi.</li>
          <li>
            Pazarlama, reklam ve tanıtım faaliyetlerinin yürütülmesi (kullanıcının
            açık rızası ile).
          </li>
        </ul>

        <h2>5. Kişisel Verilerin Aktarılması</h2>
        <p>
          Kullanıcıların kişisel verileri, yasal zorunluluklar veya aşağıdaki
          durumlar dışında üçüncü kişilerle paylaşılmamaktadır:
        </p>
        <ul>
          <li>Yetkili kamu kurum ve kuruluşlarının talepleri doğrultusunda.</li>
          <li>Hukuki süreçlerde delil olarak kullanılması gerektiğinde.</li>
          <li>
            Platformun sağlıklı bir şekilde çalışabilmesi için iş ortaklarına,
            teknik destek sağlayıcılarına aktarım.
          </li>
          <li>
            Kullanıcının onayı ile reklam veya tanıtım amaçlı iş ortaklarıyla
            paylaşım.
          </li>
        </ul>

        <h2>6. Kişisel Verilerin Saklanma Süresi</h2>
        <p>
          Kişisel verileriniz, işlendikleri amaç için gerekli olan süre boyunca
          saklanır. Ayrıca, ilgili mevzuat hükümlerine uyum amacıyla yasal süreler
          boyunca muhafaza edilir. Süre dolduğunda, kişisel veriler güvenli bir
          şekilde silinir, yok edilir veya anonim hale getirilir.
        </p>

        <h2>7. Veri Güvenliğine İlişkin Önlemler</h2>
        <p>
          Kişisel verilerinizin güvenliğini sağlamak amacıyla aşağıdaki teknik ve
          idari önlemler alınmıştır:
        </p>
        <ul>
          <li>Verilerin şifrelenmesi ve güvenli sunucularda saklanması.</li>
          <li>Yetkisiz erişimi önlemek için erişim kısıtlamalarının uygulanması.</li>
          <li>
            Sistemlerin düzenli olarak izlenmesi ve güvenlik açıklarına karşı
            korunması.
          </li>
        </ul>

        <h2>8. Kullanıcının Hakları</h2>
        <p>
          KVKK’nın 11. maddesi uyarınca, kullanıcılar aşağıdaki haklara sahiptir:
        </p>
        <ul>
          <li>Kişisel verilerinin işlenip işlenmediğini öğrenme.</li>
          <li>İşlenmişse buna ilişkin bilgi talep etme.</li>
          <li>
            Verilerin işlenme amacını öğrenme ve amacına uygun kullanılıp
            kullanılmadığını sorgulama.
          </li>
          <li>Yanlış veya eksik işlenmiş verilerin düzeltilmesini isteme.</li>
          <li>Kişisel verilerin silinmesini veya yok edilmesini talep etme.</li>
          <li>Verilerin aktarıldığı üçüncü kişileri öğrenme.</li>
          <li>
            İşlenen verilerin münhasıran otomatik sistemlerle analiz edilmesi
            suretiyle kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme.
          </li>
          <li>
            Kanuna aykırı işlenen veriler nedeniyle zarara uğraması halinde
            tazminat talep etme.
          </li>
        </ul>

        <h2>9. Güncellemeler ve Değişiklikler</h2>
        <p>
          Bu Aydınlatma Metni, gerekli görüldüğünde güncellenebilir. Güncellenen
          metin, platform üzerinde yayınlandığı tarihten itibaren geçerli
          olacaktır.
        </p>
		
		 <h2>Şirket Bilgileri</h2>
        <ul>
          <li>
            <strong>Şirket Adı:</strong> Tungasoft Yazılım Eğitim ve Danışmanlık
            Anonim Şirketi
          </li>
          <li>
            <strong>Adres:</strong> 75. Yıl OSB, Bilim Caddesi No: 5/107,
            Odunpazarı, Eskişehir
          </li>
          <li>
            <strong>Telefon:</strong> +90 850 305 75 62
          </li>
          <li>
            <strong>E-posta:</strong>{" "}
            <a href="mailto:info@tungasoft.com">info@tungasoft.com</a>
          </li>
          <li>
            <strong>Vergi Dairesi ve Numarası:</strong> Eskişehir V.D -{" "}
            8670892656
          </li>
          <li>
            <strong>Mersis No:</strong> 0867089265600001
          </li>
        </ul>
    
		
		
      </section>

      <footer className="terms-footer">
        <p>
          <strong>hocabul.net</strong>, <strong>Tungasoft Yazılım Eğitim ve
          Danışmanlık Anonim Şirketi</strong> tarafından geliştirilmiştir.
        </p>
      </footer>
    </div>
  );
};

export default Privacy;
