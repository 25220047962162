import React from "react";
import "./Terms.css"; // Aynı CSS dosyasını kullanıyoruz

const About = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Hakkımızda</h1>
      </header>

      <section className="terms-content">
        <h2>hocabul.net Nedir?</h2>
        <p>
          Hocabul.net, öğrenciler ve özel ders öğretmenlerini bir araya getiren
          modern bir eğitim platformudur. <strong>Tungasoft Yazılım Eğitim ve
          Danışmanlık Anonim Şirketi</strong> tarafından geliştirilmiş olan
          Hocabul.net, eğitimde fırsat eşitliği sağlayarak öğrencilere en iyi
          öğretmenlerle buluşma imkânı sunmayı hedefler.
        </p>
        <p>
          Platformumuz, kullanıcı dostu arayüzü ve teknolojik altyapısıyla,
          eğitimde yenilikçi çözümlere sunmakta ve arge faaliyetlerine sürekli devam etmektedir. 
        </p>

        <h2>Misyonumuz</h2>
        <p>
          Eğitimde bireyselleştirilmiş çözümler sunarak her öğrencinin hedeflerine
          ulaşmasını sağlamak ve kaliteli eğitimi herkes için erişilebilir hale
          getirmek.
        </p>

        <h2>Vizyonumuz</h2>
        <p>
          Türkiye’nin en güvenilir ve en kapsamlı özel ders platformu olmak;
          yenilikçi yaklaşımımızla eğitimde dijital dönüşümün öncüsü olmak.
        </p>

        <h2>Neler Sunuyoruz?</h2>
        <ul>
          <li>
            <strong>Öğrenciler İçin:</strong> İhtiyaç duydukları derslerde uzman
            öğretmenlerle çalışma imkânı. Kullanıcıların kolayca öğretmen
            profillerini incelemesi, randevu alması ve geri bildirimde bulunması
            sağlanır.
          </li>
          <li>
            <strong>Öğretmenler İçin:</strong> Kendi uzmanlık alanlarında ders
            vermek isteyen öğretmenlere görünürlük kazandırarak, kazançlarını
            artırmalarına destek oluruz.
          </li>
          <li>
            <strong>Ebeveynler İçin:</strong> Çocuklarının eğitim ihtiyaçlarına
            uygun öğretmenleri kolayca bulmaları için pratik ve güvenli bir ortam
            sunarız.
          </li>
        </ul>

        <h2>Değerlerimiz</h2>
        <ul>
          <li>
            <strong>Güvenilirlik:</strong> Öğrenciler, öğretmenler ve aileler için
            güvenilir bir platform olmak.
          </li>
          <li>
            <strong>Şeffaflık:</strong> Tüm süreçlerde kullanıcılarımıza açık ve
            net bilgiler sunarız.
          </li>
          <li>
            <strong>Yenilikçilik:</strong> Eğitimde en son teknolojileri kullanarak
            daha etkili ve verimli çözümler üretiriz.
          </li>
          <li>
            <strong>Kapsayıcılık:</strong> Her seviyeden ve her yaştan birey için
            öğrenme fırsatları yaratırız.
          </li>
        </ul>

       

        <h2>Ekibimiz</h2>
        <p>
          Hocabul.net, alanında uzman yazılım geliştiriciler, eğitim danışmanları
          ve müşteri destek ekibiyle hizmet vermektedir. Ekibimiz, kullanıcılarımızın
          ihtiyaçlarını karşılamak için sürekli olarak çalışmakta ve kendini
          geliştirmektedir.
        </p>
      </section>

      <footer className="terms-footer">
        <p>
          <strong>hocabul.net</strong>, <strong>Tungasoft Yazılım Eğitim ve
          Danışmanlık Anonim Şirketi</strong> tarafından geliştirilmiştir.
        </p>
      </footer>
    </div>
  );
};

export default About;
