import React, { useState, useEffect } from 'react';

const MembershipUpdate = () => {
  const [teachers, setTeachers] = useState([]); // Uygun öğretmenlerin listesi
  const [selectedEmail, setSelectedEmail] = useState(''); // Seçilen öğretmen e-postası
  const [isUpdating, setIsUpdating] = useState(false); // Yükleme durumu
  const [successMessage, setSuccessMessage] = useState(''); // Başarı mesajı
  const [errorMessage, setErrorMessage] = useState(''); // Hata mesajı

  // Uygun öğretmenleri getir
  const fetchEligibleTeachers = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/get-approved-free-members');
      if (!response.ok) {
        throw new Error(`API Hatası: ${response.status}`);
      }
      const data = await response.json();
      setTeachers(data); // Uygun öğretmenleri state'e ata
    } catch (error) {
      console.error('Uygun öğretmenler alınırken hata oluştu:', error);
      setErrorMessage('Uygun öğretmenler alınırken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

 const handleMembershipUpdate = async (event) => {
  event.preventDefault();
  setIsUpdating(true);
  setSuccessMessage('');
  setErrorMessage('');

  try {
    const response = await fetch('https://hocabul.net/api/update-membership', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        email: selectedEmail,
        newMembershipType: 'yearly-type',
      }),
    });

    if (response.ok) {
      const data = await response.json();
      setSuccessMessage('Üyelik başarıyla yükseltildi.');
      console.log('Güncellenen Üye:', data.updatedMember);
      setSelectedEmail('');
      fetchEligibleTeachers(); // Listeyi yenile
    } else {
      throw new Error('Üyelik yükseltme başarısız.');
    }
  } catch (error) {
    console.error('Üyelik yükseltilirken hata oluştu:', error);
    setErrorMessage('Üyelik yükseltilirken bir hata oluştu. Lütfen tekrar deneyin.');
  } finally {
    setIsUpdating(false);
  }
};


  // Sayfa yüklendiğinde uygun öğretmenleri çek
  useEffect(() => {
    fetchEligibleTeachers();
  }, []);

 return (
    <div className="membership-update">
      <h2>Üyelik Türü Yükseltme</h2>
      {successMessage && <p className="success-message">{successMessage}</p>}
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <form onSubmit={handleMembershipUpdate}>
        <div>
          <label>Bir öğretmen seçin:</label>
          <select
            value={selectedEmail}
            onChange={(e) => setSelectedEmail(e.target.value)}
            required
          >
            <option value="">Bir öğretmen seçin</option>
            {teachers.map((teacher) => (
              <option key={teacher.id} value={teacher.email}>
                {teacher.name} {teacher.lastname}
              </option>
            ))}
          </select>
        </div>
        <button className="approve-btn" type="submit" disabled={isUpdating}>
          {isUpdating ? 'Yükleniyor...' : 'Yükselt'}
        </button>
      </form>
    </div>
  );
};


export default MembershipUpdate;
