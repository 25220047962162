import React, { useState, useEffect } from "react";
import "./ContentManagement.css";

const ContentManagement = () => {
  const [content, setContent] = useState({ videos: [], news: [] });
  const [editingItem, setEditingItem] = useState(null);
  const [form, setForm] = useState({ title: "", url: "", description: "", date: "" });
  const [newItem, setNewItem] = useState({ type: "video", title: "", url: "", description: "", date: "" });
  const [statusMessage, setStatusMessage] = useState(""); // Hata veya başarı mesajları

  // İçerikleri Fetch Et
  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = async () => {
    try {
      const response = await fetch("https://hocabul.net/api/content.json");
      if (!response.ok) throw new Error("İçerik yüklenemedi.");
      const data = await response.json();
      setContent(data);
    } catch (err) {
      setStatusMessage("İçerik yüklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  // Düzenleme Moduna Geç
  const handleEdit = (item, type) => {
    setEditingItem({ ...item, type });
    setForm({
      title: item.title || "",
      url: item.url || "",
      description: item.description || "",
      date: item.date || "",
    });
    setStatusMessage(""); // Önceki mesajları temizle
  };

  // İçerik Sil
  const handleDelete = async (id, type) => {
    setStatusMessage(""); // Önceki mesajları temizle
    try {
      const response = await fetch("https://hocabul.net/api/delete-content", {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ id, type }),
      });

      if (!response.ok) throw new Error("İçerik silinemedi.");
      await fetchContent(); // Listeyi güncelle
      setStatusMessage("İçerik başarıyla silindi.");
    } catch (err) {
      setStatusMessage("İçerik silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  // İçerik Güncelle
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatusMessage(""); // Önceki mesajları temizle

    try {
      const response = await fetch("https://hocabul.net/api/update-content", {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ ...editingItem, ...form }),
      });

      if (!response.ok) throw new Error("İçerik güncellenemedi.");
      await fetchContent(); // Listeyi güncelle
      setEditingItem(null);
      setForm({ title: "", url: "", description: "", date: "" });
      setStatusMessage("İçerik başarıyla güncellendi.");
    } catch (err) {
      setStatusMessage("İçerik güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  // Yeni İçerik Ekle
  const handleAddContent = async (e) => {
    e.preventDefault();
    setStatusMessage(""); // Önceki mesajları temizle

    try {
      const response = await fetch("https://hocabul.net/api/add-content", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newItem),
      });

      if (!response.ok) throw new Error("Yeni içerik eklenemedi.");
      await fetchContent(); // Listeyi güncelle
      setNewItem({ type: "video", title: "", url: "", description: "", date: "" });
      setStatusMessage("Yeni içerik başarıyla eklendi.");
    } catch (err) {
      setStatusMessage("Yeni içerik eklenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  };

  return (
    <div className="content-management">
      <h2>İçerik Yönetimi</h2>
      {statusMessage && <p className="status-message">{statusMessage}</p>}
      <h3>Videolar</h3>
      <ul>
        {content.videos.map((video) => (
          <li key={video.id} className="content-item">
            <h3>{video.title}</h3>
            <iframe
              src={video.url}
              title={video.title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <div>
              <button className="edit-btn" onClick={() => handleEdit(video, "video")}>
                Düzenle
              </button>
              <button className="delete-btn" onClick={() => handleDelete(video.id, "video")}>
                Sil
              </button>
            </div>
          </li>
        ))}
      </ul>

      <h3>Haberler</h3>
      <ul>
        {content.news.map((news) => (
          <li key={news.id} className="content-item">
            <h3>{news.title}</h3>
            <p>{news.description}</p>
            <small>{news.date}</small>
            <div>
              <button className="edit-btn" onClick={() => handleEdit(news, "news")}>
                Düzenle
              </button>
              <button className="delete-btn" onClick={() => handleDelete(news.id, "news")}>
                Sil
              </button>
            </div>
          </li>
        ))}
      </ul>

      {editingItem && (
        <form className="content-form" onSubmit={handleSubmit}>
          <h3>İçeriği Düzenle</h3>
          <input
            type="text"
            className="form-input"
            placeholder="Başlık"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
          {editingItem.type === "video" && (
            <input
              type="text"
              className="form-input"
              placeholder="URL"
              value={form.url}
              onChange={(e) => setForm({ ...form, url: e.target.value })}
            />
          )}
          {editingItem.type === "news" && (
            <>
              <textarea
                className="form-textarea"
                placeholder="Açıklama"
                value={form.description}
                onChange={(e) => setForm({ ...form, description: e.target.value })}
              ></textarea>
              <input
                type="date"
                className="form-input"
                value={form.date}
                onChange={(e) => setForm({ ...form, date: e.target.value })}
              />
            </>
          )}
          <button className="submit-btn" type="submit">
            Güncelle
          </button>
        </form>
      )}

      <h3>Yeni İçerik Ekle</h3>
      <form className="content-form" onSubmit={handleAddContent}>
        <select
          className="form-input"
          value={newItem.type}
          onChange={(e) => setNewItem({ ...newItem, type: e.target.value })}
        >
          <option value="video">Video</option>
          <option value="news">Haber</option>
        </select>
        <input
          type="text"
          className="form-input"
          placeholder="Başlık"
          value={newItem.title}
          onChange={(e) => setNewItem({ ...newItem, title: e.target.value })}
        />
        {newItem.type === "video" && (
          <input
            type="text"
            className="form-input"
            placeholder="URL"
            value={newItem.url}
            onChange={(e) => setNewItem({ ...newItem, url: e.target.value })}
          />
        )}
        {newItem.type === "news" && (
          <>
            <textarea
              className="form-textarea"
              placeholder="Açıklama"
              value={newItem.description}
              onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
            ></textarea>
            <input
              type="date"
              className="form-input"
              value={newItem.date}
              onChange={(e) => setNewItem({ ...newItem, date: e.target.value })}
            />
          </>
        )}
        <button className="submit-btn" type="submit">
          Ekle
        </button>
      </form>
    </div>
  );
};

export default ContentManagement;
