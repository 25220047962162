import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminLogin.css';
import { AuthContext } from '../context/AuthContext';


function AdminLogin({ onLogin }) {
  const [adminEmail, setAdminEmail] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [adminCode, setAdminCode] = useState('');
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(180);
  const [error, setError] = useState('');
const { loginAsAdmin } = useContext(AuthContext); // AuthContext'ten loginAsAdmin fonksiyonunu al
  const navigate = useNavigate();
  
  
  
  useEffect(() => {
    const isAdminAuthenticated = localStorage.getItem('isAdminAuthenticated');
    if (isAdminAuthenticated) {
      navigate('/admin');
    }
  }, [navigate]);

  const handleLogin = (userData) => {
    loginAsAdmin(userData); // AuthContext ile admin olarak giriş yap
    navigate('/admin');
  };

  const checkAdminEmail = useCallback(async (email) => {
    try {
      const response = await fetch('https://hocabul.net/api/admin/verify-email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adminEmail: email }),
      });





      if (response.ok) {
        const data = await response.json();
        if (data.success) {
          setIsEmailVerified(true);
          alert('E-posta doğrulandı!');
        } else {
          setError(data.message || 'E-posta doğrulama başarısız.');
        }
      } else {
        setError('Sunucu hatası: E-posta doğrulama başarısız.');
      }
    } catch (error) {
      console.error('E-posta kontrol hatası:', error);
      setError('Sunucuya bağlanırken bir hata oluştu.');
    }
  }, []);

  const sendAdminSms = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://hocabul.net/api/sendadmin-sms', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: adminPhone }),
      });

      if (response.ok) {
        alert('Admin doğrulama kodu başarıyla gönderildi.');
        setTimer(180);
        setIsCodeSent(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Admin SMS gönderimi sırasında bir hata oluştu.');
      }
    } catch (error) {
      console.error('Admin SMS gönderim hatası:', error);
      setError('Sunucuya bağlanırken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  const verifyPhoneCode = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    try {
      const response = await fetch('https://hocabul.net/api/verify-admin-code', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ adminPhone, code: adminCode }),
      });

      if (response.ok) {
        const data = await response.json();
        alert(data.message || 'Telefon doğrulandı.');
        handleLogin();
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Doğrulama kodu hatalı.');
      }
    } catch (error) {
      console.error('Doğrulama hatası:', error);
      setError('Sunucuya bağlanırken bir hata oluştu.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isCodeSent) {
      const countdown = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);

      if (timer === 0) {
        setIsCodeSent(false);
        alert('Doğrulama kodunun süresi doldu. Lütfen yeni bir kod gönderin.');
      }

      return () => clearInterval(countdown);
    }
  }, [isCodeSent, timer]);

  return (
    <div className="admin-login-page">
      <div className="admin-login-container">
        {!isEmailVerified ? (
          <>
            <h2 className="admin-login-title">Admin Giriş</h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                checkAdminEmail(adminEmail);
              }}
            >
              <div className="input-group">
                <input
                  type="email"
                  placeholder="Admin E-posta adresinizi girin"
                  value={adminEmail}
                  onChange={(e) => setAdminEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button" disabled={loading}>
                {loading ? 'Kontrol Ediliyor...' : 'Devam Et'}
              </button>
            </form>
          </>
        ) : (
          <>
            <h2 className="admin-login-title">Telefon Doğrulaması</h2>
            {!isCodeSent ? (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  sendAdminSms();
                }}
              >
                <div className="input-group">
                  <input
                    type="tel"
                    placeholder="Telefon Numaranızı Girin"
                    value={adminPhone}
                    onChange={(e) => setAdminPhone(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="submit-button" disabled={loading}>
                  {loading ? 'SMS Gönderiliyor...' : 'SMS Gönder'}
                </button>
              </form>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  verifyPhoneCode(e);
                }}
              >
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="SMS Kodunuzu Girin"
                    value={adminCode}
                    onChange={(e) => setAdminCode(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="submit-button" disabled={loading}>
                  {loading ? 'Doğrulanıyor...' : 'Giriş Yap'}
                </button>
              </form>
            )}
            <p>Kalan Süre: {Math.floor(timer / 60)}:{String(timer % 60).padStart(2, '0')}</p>
          </>
        )}
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default AdminLogin;
