import React, { useState, useEffect, useContext } from 'react';
import TeacherCard from './TeacherCard';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i import et
import Prices from './Prices';
import ProfileManagement from '../components/ProfileManagement';
import BasicCV from './BasicCV';
import DetailedCV from './DetailedCV';
import ContentSection from './ContentSection'; // Yeni bileşeni import edin
import styled from 'styled-components';
import './ProfilePage.css';

const CVCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
`;

const CVCard = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 100%;
  max-width: 400px;
  text-align: center;

  h3 {
    font-size: 1.2em;
    margin-bottom: 15px;
    color: #333;
  }

  p {
    font-size: 1em;
    margin: 10px 0;
    color: #555;
  }

  button {
    background-color: #4d9fff;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;

    &:hover {
      background-color: #3a7ecc;
    }
  }
`;

function ProfilePage() {
  const { authState } = useContext(AuthContext); // AuthContext'ten veriyi çek
  const [activeTab, setActiveTab] = useState('dashboard');
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [teacherInfo, setTeacherInfo] = useState(null);
  const [totalRequests, setTotalRequests] = useState(0);
  const [prices, setPrices] = useState({ free: 0, monthly: 0, yearly: 0 });
  const [loading, setLoading] = useState(true);

  const userEmail = authState.user?.email || null; // Email'i doğrudan AuthContext'ten al
  const userName = authState.user?.name || 'Kullanıcı'; // İsim bilgisi

  // Fiyatları fetch et
  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const response = await fetch('https://hocabul.net/api/prices');
        if (response.ok) {
          const data = await response.json();
          setPrices(data);
        } else {
          console.error('Fiyat bilgileri alınamadı');
        }
      } catch (error) {
        console.error('Fiyatları alırken hata oluştu:', error.message);
      }
    };

    fetchPrices();
  }, []);

  // Kullanıcı bilgilerine göre gerekli fetch işlemleri
  useEffect(() => {
    if (userEmail) {
      fetchData(userEmail);
    }
  }, [userEmail]);

  const fetchData = async (email) => {
    setLoading(true);
    try {
      await Promise.all([
        fetchApplicationStatus(email),
        fetchTeacherInfo(email),
        fetchRequestCount(email),
      ]);
    } catch (error) {
      console.error('Error during data fetching:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchApplicationStatus = async (email) => {
    try {
      const response = await fetch(
        `https://hocabul.net/api/application-status?email=${encodeURIComponent(email)}`
      );
      if (response.ok) {
        const data = await response.json();
        setApplicationStatus(
          data.status === 'approved' ? 'Profiliniz Onaylandı' : 'Değerlendirmede'
        );
      } else {
        setApplicationStatus('Başvuru bulunamadı');
      }
    } catch (error) {
      console.error('Error fetching application status:', error);
    }
  };

  const fetchTeacherInfo = async (email) => {
    try {
      const response = await fetch(
        `https://hocabul.net/api/teacher-info/${encodeURIComponent(email)}`
      );
      if (response.ok) {
        const data = await response.json();
        setTeacherInfo(data);
      } else {
        console.error('Teacher data not found');
      }
    } catch (error) {
      console.error('Error fetching teacher info:', error);
    }
  };

  const fetchRequestCount = async (email) => {
    try {
      const response = await fetch(`https://hocabul.net/api/request-count/${encodeURIComponent(email)}`);
      if (response.ok) {
        const data = await response.json();
        setTotalRequests(data.count);
      } else {
        setTotalRequests(0);
      }
    } catch (error) {
      console.error('Error fetching request count:', error);
    }
  };

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  return (
    <div className="profile-page">
      <div className="sidebar">
        <div
          className={`sidebar-item ${activeTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => setActiveTab('dashboard')}
        >
          Panelim
        </div>
        <div
          className={`sidebar-item ${activeTab === 'manage-profile' ? 'active' : ''}`}
          onClick={() => setActiveTab('manage-profile')}
        >
          Profil Yönetimi
        </div>
        <div
          className={`sidebar-item ${activeTab === 'membership' ? 'active' : ''}`}
          onClick={() => setActiveTab('membership')}
        >
          Paketler
        </div>
      </div>

     <div className="content">
  {activeTab === 'dashboard' && (
    <div className="tab-content">
      {teacherInfo ? (
        <TeacherCard teacher={teacherInfo} />
      ) : (
        <p>Hoşgeldiniz, {userName} hocam. Profil kartı başvurunuzu yapabilirsiniz.</p>
      )}
      <p>
        {totalRequests > 0
          ? `Toplam Talep Sayısı: ${totalRequests}`
          : 'Henüz talep bulunmamaktadır.'}
      </p>
      <p>Kullanıcı Email: {userEmail}</p>
      
      {/* ContentSection bileşeni tab-content div'i içine alındı */}
      <ContentSection />
    </div>
  )}

        {activeTab === 'manage-profile' && (
          <div className="tab-content">
            <div className="tab-item">
              <ProfileManagement applicationStatus={applicationStatus} email={userEmail} />
            </div>
            <div className="tab-item">
              <BasicCV />
            </div>
            <div className="tab-item">
              <DetailedCV />
            </div>
          </div>
        )}

        {activeTab === 'membership' && (
          <div className="tab-content">
            <Prices />
          </div>
        )}
      </div>
    </div>
  );
}

export default ProfilePage;
