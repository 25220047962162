import React, { useEffect, useState, useContext } from 'react';
import './Prices.css';
import { AuthContext } from '../context/AuthContext';

function Prices() {
  const { authState } = useContext(AuthContext);
  const [prices, setPrices] = useState({ free: 'ÜCRETSİZ', monthly: 0, yearly: 0 });
  const [endDate, setEndDate] = useState(null);
  const [membershipType, setMembershipType] = useState(null);

  const userEmail = authState.user?.email || null;

  useEffect(() => {
    fetchPrices();
    if (userEmail) {
      fetchMemberDetails(userEmail);
    }
  }, [userEmail]);

  // Fiyatları API'den al
  const fetchPrices = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/prices');
      if (response.ok) {
        const data = await response.json();
        setPrices(data);
      } else {
        console.error('Fiyat bilgileri alınamadı');
      }
    } catch (error) {
      console.error('Fiyatları alırken hata oluştu:', error.message);
    }
  };

  // Üye bilgilerini members.json'dan al
  const fetchMemberDetails = async (email) => {
    try {
      const response = await fetch('https://hocabul.net/api/members');
      if (response.ok) {
        const members = await response.json();
        const member = members.find((m) => m.email === email);

        if (member) {
          setMembershipType(member.membership_type);
          calculateEndDate(new Date(member.membership_start_date), member.membership_type);
        } else {
          console.error('Üye bulunamadı');
        }
      } else {
        console.error('Üye bilgileri alınamadı');
      }
    } catch (error) {
      console.error('Üye bilgileri alınırken hata oluştu:', error.message);
    }
  };

  // Sona erme tarihini hesapla
  const calculateEndDate = (startDate, type) => {
    const end = new Date(startDate);

    if (type === 'free-type') {
      end.setDate(end.getDate() + 30);
    } else if (type === 'monthly-type') {
      end.setDate(end.getDate() + 30);
    } else if (type === 'yearly-type') {
      end.setDate(end.getDate() + 365);
    }

    setEndDate(end.toLocaleDateString('tr-TR')); // Tarihi "Gün/Ay/Yıl" formatında ayarla
  };

  return (
    <div className="paketler-page">
      <div className="packages">
        {/* Tanışma Package */}
        <div className="package-card">
          <h2>Tanışma</h2>
          <p className="price">ÜCRETSİZ</p>
          <p className="description">Profil Listeleme</p>
          {membershipType === 'free-type' && (
            <button className="package-button">
              Sona Erme Tarihi: {endDate || 'Hesaplanıyor...'}
            </button>
          )}
        </div>

        {/* Aylık Abonelik Package */}
        <div className="package-card">
          <h2>Aylık Abonelik</h2>
          <p className="price">{prices.monthly}TL</p>
          <p className="description">Ay/{prices.monthly} yenileme ücreti</p>
          <p className="description">Profil Listeleme</p>
          <p className="description">CV Listeleme</p>
          <p className="description">CV de telefon ve adres alanı</p>
          <p className="description">CV de hakkımda</p>
          <p className="description">CV de diğer çalışma alanları</p>
          {membershipType === 'monthly-type' && (
            <button className="package-button">
              Sona Erme Tarihi: {endDate || 'Hesaplanıyor...'}
            </button>
          )}
          {membershipType !== 'monthly-type' && (
            <button className="purchase-button">Satın Al</button>
          )}
        </div>

        {/* Yıllık Abonelik Package */}
        <div className="package-card popular">
          <h3 className="popular-label">En popüler</h3>
          <h2>Yıllık Abonelik</h2>
          <p className="price">{prices.yearly}TL</p>
          <p className="description">Yıl/{prices.yearly} TL yenileme ücreti</p>
          <p className="description">Profil Listeleme</p>
          <p className="description">CV Listeleme</p>
          <p className="description">CV de telefon ve adres</p>
          <p className="description">CV de hakkımda</p>
          <p className="description">CV de diğer çalışma alanları</p>
          <p className="description">CV de sosyal medya hesapları</p>
          <p className="description">CV de Youtube videosu</p>
          <p className="description">Etkili CV oluşturma danışmanlığı</p>
          {membershipType === 'yearly-type' && (
            <button className="package-button">
              Sona Erme Tarihi: {endDate || 'Hesaplanıyor...'}
            </button>
          )}
          {membershipType !== 'yearly-type' && (
            <button className="purchase-button">Satın Al</button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Prices;
