import React, { useEffect, useState } from "react";
import "./ContentSection.css"; // CSS dosyasını dahil ediyoruz

const ContentSection = () => {
  const [content, setContent] = useState({ videos: [], news: [] });

  useEffect(() => {
    fetch("https://hocabul.net/api/content.json")
      .then((res) => res.json())
      .then((data) => setContent(data))
      .catch((err) => console.error("Error loading content:", err));
  }, []);

  return (
    <div className="content-container">
      {/* Render Videos */}
      <h2>Videolar</h2>
      {content.videos.map((video) => (
        <div className="video-container" key={video.id}>
          <div className="video-title-container">
            <h3>{video.title}</h3>
          </div>
          <iframe
            src={video.url}
            title={video.title}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ))}

      {/* Render News */}
      <h2>Haberler</h2>
      {content.news.map((news) => (
        <div className="news-container" key={news.id}>
          <h3>{news.title}</h3>
          <p>{news.description}</p>
          <small>{news.date}</small>
        </div>
      ))}
    </div>
  );
};

export default ContentSection;





