// src/components/Profile.js
import React from 'react';
import styled from 'styled-components';
import SocialIcons from './SocialIcons';

const ProfileContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

const ProfileName = styled.h2`
  font-size: 1.4em;
  color: #000 !important; /* Ensure black color */
  font-weight: bold; /* Make text bold */
  margin-bottom: 10px;
`;


function Profile({ name, photo }) {
  return (
    <ProfileContainer>
      <ProfileImage src={photo} alt={name} />
      <ProfileName>{name}</ProfileName>
      <SocialIcons />
    </ProfileContainer>
  );
}

export default Profile;
