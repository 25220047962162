
// src/pages/Contact.js
import React, { useState } from 'react';
import './Contact.css';

function Contact() {
    const [formData, setFormData] = useState({
        name: '',
        lastname: '',
        email: '',
        university: '',
        department: '',
        experience: '',
        category: '',
        subject: '',
        time: '',
        format: '',
        fee: '',
        yer: '', // New field for city
        description: ''
    });

    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://hocabul.net/api/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                setSubmitted(true);
                console.log('Form submitted successfully');
            } else {
                console.error('Failed to submit form');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };


   const universities = [
    "Abdullah Gül Üniversitesi",
    "Acıbadem Üniversitesi",
    "Adana Alparslan Türkeş Bilim ve Teknoloji Üniversitesi",
    "Adıyaman Üniversitesi",
    "Afyon Kocatepe Üniversitesi",
    "Afyonkarahisar Sağlık Bilimleri Üniversitesi",
    "Ağrı İbrahim Çeçen Üniversitesi",
    "Akdeniz Üniversitesi",
    "Aksaray Üniversitesi",
    "Alanya Alaaddin Keykubat Üniversitesi",
    "Alanya Üniversitesi",
    "Altınbaş Üniversitesi",
    "Amasya Üniversitesi",
    "Anadolu Üniversitesi",
    "Ankara Bilim Üniversitesi",
    "Ankara Hacı Bayram Veli Üniversitesi",
    "Ankara Medipol Üniversitesi",
    "Ankara Müzik ve Güzel Sanatlar Üniversitesi",
    "Ankara Sosyal Bilimler Üniversitesi",
    "Ankara Üniversitesi",
    "Ankara Yıldırım Beyazıt Üniversitesi",
    "Ardahan Üniversitesi",
    "Artvin Çoruh Üniversitesi",
    "Atatürk Üniversitesi",
    "Atılım Üniversitesi",
    "Avrasya Üniversitesi",
    "Aydın Adnan Menderes Üniversitesi",
    "Bahçeşehir Üniversitesi",
    "Balıkesir Üniversitesi",
    "Bandırma Onyedi Eylül Üniversitesi",
    "Bartın Üniversitesi",
    "Başkent Üniversitesi",
    "Batman Üniversitesi",
    "Bayburt Üniversitesi",
    "Bezmialem Vakıf Üniversitesi",
    "Beykoz Üniversitesi",
    "Bilecik Şeyh Edebali Üniversitesi",
    "Biruni Üniversitesi",
    "Boğaziçi Üniversitesi",
    "Bolu Abant İzzet Baysal Üniversitesi",
    "Bursa Teknik Üniversitesi",
    "Bursa Uludağ Üniversitesi",
    "Çanakkale Onsekiz Mart Üniversitesi",
    "Çankaya Üniversitesi",
    "Çankırı Karatekin Üniversitesi",
    "Çağ Üniversitesi",
    "Çukurova Üniversitesi",
    "Demiroğlu Bilim Üniversitesi",
    "Dicle Üniversitesi",
    "Dokuz Eylül Üniversitesi",
    "Doğuş Üniversitesi",
    "Düzce Üniversitesi",
    "Ege Üniversitesi",
    "Erciyes Üniversitesi",
    "Erzincan Binali Yıldırım Üniversitesi",
    "Erzurum Teknik Üniversitesi",
    "Eskişehir Osmangazi Üniversitesi",
    "Eskişehir Teknik Üniversitesi",
    "Fenerbahçe Üniversitesi",
    "Fatih Sultan Mehmet Üniversitesi",
    "Fırat Üniversitesi",
    "Galatasaray Üniversitesi",
    "Gaziantep İslam Bilim ve Teknoloji Üniversitesi",
    "Gaziantep Üniversitesi",
    "Gebze Teknik Üniversitesi",
    "Giresun Üniversitesi",
    "Gümüşhane Üniversitesi",
    "Hacettepe Üniversitesi",
    "Hakkari Üniversitesi",
    "Haliç Üniversitesi",
    "Harran Üniversitesi",
    "Hasan Kalyoncu Üniversitesi",
    "Hatay Mustafa Kemal Üniversitesi",
    "Hitit Üniversitesi",
    "Iğdır Üniversitesi",
    "İbn Haldun Üniversitesi",
    "İhsan Doğramacı Bilkent Üniversitesi",
    "Işık Üniversitesi",
    "İskenderun Teknik Üniversitesi",
    "İstanbul 29 Mayıs Üniversitesi",
    "İstanbul Arel Üniversitesi",
    "İstanbul Atlas Üniversitesi",
    "İstanbul Aydın Üniversitesi",
    "İstanbul Beykent Üniversitesi",
    "İstanbul Bilgi Üniversitesi",
    "İstanbul Esenyurt Üniversitesi",
    "İstanbul Galata Üniversitesi",
    "İstanbul Gedik Üniversitesi",
    "İstanbul Gelişim Üniversitesi",
    "İstanbul Kent Üniversitesi",
    "İstanbul Kültür Üniversitesi",
    "İstanbul Medeniyet Üniversitesi",
    "İstanbul Medipol Üniversitesi",
    "İstanbul Nişantaşı Üniversitesi",
    "İstanbul Okan Üniversitesi",
    "İstanbul Rumeli Üniversitesi",
    "İstanbul Sabahattin Zaim Üniversitesi",
    "İstanbul Sağlık ve Teknoloji Üniversitesi",
    "İstanbul Teknik Üniversitesi",
    "İstanbul Ticaret Üniversitesi",
    "İstanbul Topkapı Üniversitesi",
    "İstanbul Üniversitesi",
    "İstanbul Üniversitesi-Cerrahpaşa",
    "İstanbul Yeni Yüzyıl Üniversitesi",
    "İstinye Üniversitesi",
    "İzmir Bakırçay Üniversitesi",
    "İzmir Demokrasi Üniversitesi",
    "İzmir Ekonomi Üniversitesi",
    "İzmir Kâtip Çelebi Üniversitesi",
    "İzmir Tınaztepe Üniversitesi",
    "İzmir Yüksek Teknoloji Enstitüsü",
    "Jandarma ve Sahil Güvenlik Akademisi",
    "Kadir Has Üniversitesi",
    "Kafkas Üniversitesi",
    "Kahramanmaraş İstiklal Üniversitesi",
    "Kahramanmaraş Sütçü İmam Üniversitesi",
    "Kapadokya Üniversitesi",
    "Karabük Üniversitesi",
    "Karadeniz Teknik Üniversitesi",
    "Karamanoğlu Mehmetbey Üniversitesi",
    "Kastamonu Üniversitesi",
    "Kayseri Üniversitesi",
    "Kırıkkale Üniversitesi",
    "Kırklareli Üniversitesi",
    "Kırşehir Ahi Evran Üniversitesi",
    "Kilis 7 Aralık Üniversitesi",
    "Koç Üniversitesi",
    "Konya Gıda ve Tarım Üniversitesi",
    "Konya Teknik Üniversitesi",
    "Kocaeli Sağlık ve Teknoloji Üniversitesi",
    "Kocaeli Üniversitesi",
    "KTO Karatay Üniversitesi",
    "Kütahya Dumlupınar Üniversitesi",
    "Kütahya Sağlık Bilimleri Üniversitesi",
    "Lokman Hekim Üniversitesi",
    "Maltepe Üniversitesi",
    "Manisa Celal Bayar Üniversitesi",
    "Mardin Artuklu Üniversitesi",
    "Marmara Üniversitesi",
    "MEF Üniversitesi",
    "Mersin Üniversitesi",
    "Mimar Sinan Güzel Sanatlar Üniversitesi",
    "Munzur Üniversitesi",
    "Muğla Sıtkı Koçman Üniversitesi",
    "Muş Alparslan Üniversitesi",
    "Necmettin Erbakan Üniversitesi",
    "Nevşehir Hacı Bektaş Veli Üniversitesi",
    "Niğde Ömer Halisdemir Üniversitesi",
    "Nuh Naci Yazgan Üniversitesi",
    "Ondokuz Mayıs Üniversitesi",
    "Ordu Üniversitesi",
    "Ostim Teknik Üniversitesi",
    "Özyeğin Üniversitesi",
    "Pamukkale Üniversitesi",
    "Piri Reis Üniversitesi",
    "Polis Akademisi",
    "Recep Tayyip Erdoğan Üniversitesi",
    "Sabancı Üniversitesi",
    "Sağlık Bilimleri Üniversitesi",
    "Sakarya Uygulamalı Bilimler Üniversitesi",
    "Sakarya Üniversitesi",
    "Sanko Üniversitesi",
    "Selçuk Üniversitesi",
    "Siirt Üniversitesi",
    "Sinop Üniversitesi",
    "Sivas Bilim ve Teknoloji Üniversitesi",
    "Sivas Cumhuriyet Üniversitesi",
    "Şırnak Üniversitesi",
    "TED Üniversitesi",
    "Tekirdağ Namık Kemal Üniversitesi",
    "TOBB Ekonomi ve Teknoloji Üniversitesi",
    "Tokat Gaziosmanpaşa Üniversitesi",
    "Toros Üniversitesi",
    "Trakya Üniversitesi",
    "Trabzon Üniversitesi",
    "Türk Hava Kurumu Üniversitesi",
    "Türk-Alman Üniversitesi",
    "Türk-Japon Bilim ve Teknoloji Üniversitesi",
    "Ufuk Üniversitesi",
    "Uludağ Üniversitesi",
    "Uşak Üniversitesi",
    "Üsküdar Üniversitesi",
    "Van Yüzüncü Yıl Üniversitesi",
    "Yaşar Üniversitesi",
    "Yeditepe Üniversitesi",
    "Yıldız Teknik Üniversitesi",
    "Yozgat Bozok Üniversitesi",
    "Yüksek İhtisas Üniversitesi",
    "Yalova Üniversitesi",
    "Zonguldak Bülent Ecevit Üniversitesi"
];


    const cities = [
         "İstanbul Avrupa", "İstanbul Anadolu", "Ankara",, "İzmir", "Adana", "Adıyaman", "Afyonkarahisar", "Ağrı", "Aksaray", "Amasya", "Antalya",
        "Ardahan", "Artvin", "Aydın", "Balıkesir", "Bartın", "Batman", "Bayburt", "Bilecik",
        "Bingöl", "Bitlis", "Bolu", "Burdur", "Bursa", "Çanakkale", "Çankırı", "Çorum", "Denizli",
        "Diyarbakır", "Düzce", "Edirne", "Elazığ", "Erzincan", "Erzurum", "Eskişehir", "Gaziantep",
        "Giresun", "Gümüşhane", "Hakkâri", "Hatay", "Iğdır", "Isparta", "Kahramanmaraş",
        "Karabük", "Karaman", "Kars", "Kastamonu", "Kayseri", "Kırıkkale", "Kırklareli", "Kırşehir", "Kilis",
        "Kocaeli", "Konya", "Kütahya", "Malatya", "Manisa", "Mardin", "Mersin", "Muğla", "Muş", "Nevşehir",
        "Niğde", "Ordu", "Osmaniye", "Rize", "Sakarya", "Samsun", "Siirt", "Sinop", "Sivas", "Şanlıurfa",
        "Şırnak", "Tekirdağ", "Tokat", "Trabzon", "Tunceli", "Uşak", "Van", "Yalova", "Yozgat", "Zonguldak"
    ];

    return (
        <div className="teacher-application-form">
            <h2>Öğretmen Başvurusu</h2>
            {!submitted ? (
                <form onSubmit={handleSubmit}>
                    <label htmlFor="name">Adınız:</label>
                    <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />

                    <label htmlFor="lastname">Soyadınız:</label>
                    <input type="text" id="lastname" name="lastname" value={formData.lastname} onChange={handleChange} required />

                    <label htmlFor="email">E-posta Adresiniz:</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />

                    <label htmlFor="university">Mezun olduğunuz üniversite:</label>
                    <select id="university" name="university" value={formData.university} onChange={handleChange} required>
                        <option value="">Üniversite Seçin</option>
                        {universities.map((university, index) => (
                            <option key={index} value={university}>{university}</option>
                        ))}
                    </select>

                    <label htmlFor="department">Mezun olduğunuz bölüm:</label>
                    <input type="text" id="department" name="department" value={formData.department} onChange={handleChange} required />

                    <label htmlFor="experience">Özel ders tecrübe yılınız:</label>
                    <input type="number" id="experience" name="experience" value={formData.experience} onChange={handleChange} min="0" required />

                    <label htmlFor="category">Hangi kategoride listelenmek istiyorsunuz:</label>
                    <select id="category" name="category" value={formData.category} onChange={handleChange} required>
                        <option value="">Kategori Seçin</option>
                        <option value="ilkokul">İlkokul</option>
                        <option value="ortaokul">Ortaokul</option>
                        <option value="lise">Lise</option>
                        <option value="üniversite">Üniversite</option>
                        <option value="kişisel-gelişim">Kişisel Gelişim</option>
                    </select>

                    <label htmlFor="subject">Hangi dersi veriyorsunuz:</label>
                    <input type="text" id="subject" name="subject" value={formData.subject} onChange={handleChange} required />

                    <label htmlFor="time">Ders Süresi:</label>
                    <input type="text" id="time" name="time" value={formData.time} onChange={handleChange} required />

                    <label htmlFor="format">Ders Formatı:</label>
                    <select id="format" name="format" value={formData.format} onChange={handleChange} required>
                        <option value="">Format Seçin</option>
                        <option value="online">Online</option>
                        <option value="yüzyüze">Yüzyüze</option>
                        <option value="yüzyüze veya online">Yüzyüze veya Online</option>
                    </select>

                    <label htmlFor="fee">Ücret:</label>
                    <input type="text" id="fee" name="fee" value={formData.fee} onChange={handleChange} required />

                    <label htmlFor="yer">Şehir:</label>
                    <select id="yer" name="yer" value={formData.yer} onChange={handleChange} required>
                        <option value="">Şehir Seçin</option>
                        {cities.map((city, index) => (
                            <option key={index} value={city}>{city}</option>
                        ))}
                    </select>

                    <label htmlFor="description">Kendinizi tanıtan sizi farklı kılan yönlerinizi belirtiniz:</label>
                    <textarea id="description" name="description" value={formData.description} onChange={handleChange} rows="4" required></textarea>

                    <button type="submit">Başvuruyu Gönder</button>
                </form>
            ) : (
                <div className="submission-message">
                    <p>Sayın Hocam,</p>
                    <p>Platformumuza ilginiz için teşekkür ederiz. Başvurunuz bize ulaştı. Şimdi bizden 24 saat içinde bir mail alacaksınız.</p>
                    <p>İyi günler dileriz.</p>
                </div>
            )}
        </div>
    );
}

export default Contact;