import React, { useState, useEffect, useContext } from 'react';
import './DetailedCVApplication.css';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i import et

function DetailedCVApplication() {
  const { authState } = useContext(AuthContext); // AuthContext'ten kullanıcı bilgilerini al
  const [formData, setFormData] = useState({
    linkedin: '',
    instagram: '',
    youtubeChannel: '', // YouTube Channel Link
    youtubePromo: '', // YouTube Promo Video Link
    tiktok: '',
    web: '',
  });
  const [email, setEmail] = useState(authState.user?.email || ''); // AuthContext'ten e-posta al
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // AuthContext'ten e-posta kontrolü
    if (!email) {
      alert('Kimlik doğrulaması başarısız. Lütfen tekrar giriş yapın.');
    }
  }, [email]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setError(''); // Kullanıcı düzenleme yaptığında hata mesajını temizle
  };

  const validateForm = () => {
    // En az bir sosyal medya linki sağlanmalı
    const { linkedin, instagram, youtubeChannel, tiktok, web } = formData;
    return linkedin || instagram || youtubeChannel || tiktok || web;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setError('Lütfen en az bir sosyal medya hesabı ekleyiniz.');
      return;
    }

    try {
      const response = await fetch('https://hocabul.net/api/detailed-cv', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          email, // E-posta bilgisi AuthContext'ten alınıp gönderiliyor
        }),
      });

      if (response.ok) {
        setSubmitted(true);
        console.log('Form successfully submitted.');
      } else {
        const errorResponse = await response.json();
        alert(errorResponse.message || 'Bir hata oluştu.');
      }
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };

  return (
    <div className="detailed-cv-form">
      <h2>Detaylı CV Başvurusu</h2>
      <p className="form-note">
        Sosyal medya hesaplarınızı ve tanıtım videonuzu eklemek, profil prestijinizi önemli ölçüde artıracaktır.
      </p>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          {error && <p className="error-message">{error}</p>}

          {/* E-posta */}
          <label htmlFor="email">E-posta Adresiniz:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            readOnly // E-posta alanı yalnızca okunabilir
          />

          <label htmlFor="linkedin">LinkedIn:</label>
          <input
            type="url"
            id="linkedin"
            name="linkedin"
            value={formData.linkedin}
            onChange={handleChange}
            placeholder="LinkedIn profil linkinizi giriniz (isteğe bağlı)"
          />

          <label htmlFor="instagram">Instagram:</label>
          <input
            type="url"
            id="instagram"
            name="instagram"
            value={formData.instagram}
            onChange={handleChange}
            placeholder="Instagram hesabınızı giriniz (isteğe bağlı)"
          />

          <label htmlFor="youtubeChannel">YouTube Kanal Linki:</label>
          <input
            type="url"
            id="youtubeChannel"
            name="youtubeChannel"
            value={formData.youtubeChannel}
            onChange={handleChange}
            placeholder="YouTube kanal linkinizi giriniz (isteğe bağlı)"
          />

          <label htmlFor="youtubePromo">YouTube Tanıtım Video Linki:</label>
          <input
            type="url"
            id="youtubePromo"
            name="youtubePromo"
            value={formData.youtubePromo}
            onChange={handleChange}
            placeholder="YouTube tanıtım video linkinizi giriniz"
          />

          <label htmlFor="tiktok">TikTok:</label>
          <input
            type="url"
            id="tiktok"
            name="tiktok"
            value={formData.tiktok}
            onChange={handleChange}
            placeholder="TikTok hesabınızı giriniz (isteğe bağlı)"
          />

          <label htmlFor="web">Web Sitesi:</label>
          <input
            type="url"
            id="web"
            name="web"
            value={formData.web}
            onChange={handleChange}
            placeholder="Web sitesi linkinizi giriniz (isteğe bağlı)"
          />

          <button type="submit">Başvuruyu Gönder</button>
        </form>
      ) : (
        <div className="submission-message">
          <p>Başvurunuz başarıyla alınmıştır. Teşekkür ederiz!</p>
        </div>
      )}
    </div>
  );
}

export default DetailedCVApplication;
