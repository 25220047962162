import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./SignUp.css";
import { AuthContext } from "../context/AuthContext";

function Signup() {
  const [step, setStep] = useState(1); // 1: Email doğrulama, 2: SMS gönderme
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(180); // 3 dakika
  const navigate = useNavigate();
  const { loginAsTeacher } = useContext(AuthContext);

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidName = (name) => /^[a-zA-ZığüşöçİĞÜŞÖÇ\s]+$/.test(name.trim());
  const isValidPhone = (phone) => /^\d{10}$/.test(phone);

  // SMS timer
  useEffect(() => {
    let countdown;
    if (isCodeSent) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 1) {
            return prevTimer - 1;
          } else {
            clearInterval(countdown);
            setIsCodeSent(false);
            alert("Doğrulama kodunun süresi doldu. Tekrar gönderiniz.");
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [isCodeSent]);

  const verifyEmail = async (e) => {
  e.preventDefault();
  if (!isValidEmail(email)) {
    alert("Lütfen geçerli bir email adresi giriniz.");
    return;
  }

  setLoading(true);
  try {
    const response = await fetch("https://hocabul.net/api/check-email", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();
    if (response.ok) {
      if (data.redirect === "/login") {
        alert(data.message || "Bu email ile daha önce kayıt yapılmış. Giriş yapabilirsiniz.");
        navigate("/login"); // Login sayfasına yönlendirme
      } else {
        alert(data.message || "Email doğrulandı.");
        setStep(2); // İkinci aşamaya geç
      }
    } else {
      alert(data.message || "Email doğrulaması başarısız.");
    }
  } catch (error) {
    console.error("Email doğrulama hatası:", error);
    alert("Email doğrulama sırasında bir hata oluştu.");
  } finally {
    setLoading(false);
  }
};


  // SMS gönderme
  const sendSMS = async (e) => {
    e.preventDefault();
    if (!isValidName(firstName) || !isValidName(lastName)) {
      alert("Ad ve soyad sadece harflerden oluşmalıdır.");
      return;
    }

    if (!isValidPhone(phone)) {
      alert("Telefon numarası 10 haneli olmalıdır (örnek: 5XXXXXXXXX).");
      return;
    }

    if (password.length < 6) {
      alert("Şifre en az 6 karakter olmalıdır.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("https://hocabul.net/api/send-sms", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email,
          name: `${firstName} ${lastName}`,
          password,
          phone,
        }),
      });

      const data = await response.json();
      if (response.ok) {
        alert(data.message || "SMS başarıyla gönderildi.");
        setIsCodeSent(true);
        setTimer(180); // Timer başlat
      } else {
        alert(data.message || "SMS gönderimi başarısız.");
      }
    } catch (error) {
      console.error("SMS gönderim hatası:", error);
      alert("SMS gönderimi sırasında bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  // Kod doğrulama
  const verifyCode = async (e) => {
    e.preventDefault();
    if (!code) {
      alert("Lütfen SMS kodunu giriniz.");
      return;
    }

    setLoading(true);
    try {
      const response = await fetch("https://hocabul.net/api/verify-code", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, code }),
      });

      const data = await response.json();
      if (response.ok) {
        alert(data.message || "Kayıt tamamlandı.");
        loginAsTeacher({ email: data.user.email, name: data.user.name });
        navigate("/profile");
      } else {
        alert(data.message || "Kod doğrulaması başarısız.");
      }
    } catch (error) {
      console.error("Kod doğrulama hatası:", error);
      alert("Kod doğrulama sırasında bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="signup-page">
      <h2 className="signup-title">Hesap Aç</h2>
      <div className="signup-container">
        {step === 1 && (
          <form onSubmit={verifyEmail}>
            <div className="input-group">
              <input
                type="email"
                placeholder="E-posta"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Doğrulanıyor..." : "Doğrula"}
            </button>
          </form>
        )}

        {step === 2 && (
          <>
            <form onSubmit={sendSMS}>
              <div className="input-group">
                <input
                  type="text"
                  placeholder="Ad"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
                <input
                  type="text"
                  placeholder="Soyad"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
                <input
                  type="password"
                  placeholder="Şifre"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <input
                  type="tel"
                  placeholder="Telefon (5XXXXXXXXX)"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-button" disabled={loading}>
                {loading ? "Gönderiliyor..." : "SMS Gönder"}
              </button>
            </form>
          </>
        )}

        {isCodeSent && (
          <form onSubmit={verifyCode}>
            <div className="input-group">
              <input
                type="text"
                placeholder="SMS Kodunu Gir"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="submit-button" disabled={loading}>
              {loading ? "Doğrulanıyor..." : "Doğrula"}
            </button>
            <p>
              Kalan Süre: {String(Math.floor(timer / 60)).padStart(2, "0")}:
              {String(timer % 60).padStart(2, "0")}
            </p>
          </form>
        )}
      </div>
    </div>
  );
}

export default Signup;
