import React from "react";
import "./Terms.css"; // Temaya uygun stil için aynı CSS dosyası kullanılıyor

const DeliveryAndRefund = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Teslimat ve İade Şartları</h1>
        <p>
          <strong>Yürürlük Tarihi:</strong> [27/11/2024]
        </p>
      </header>

      <section className="terms-content">
        <h2>Teslimat Şartları</h2>
        <p>
          Satın alınan abonelik paketleri, ödemenin başarılı bir şekilde
          tamamlanmasının ardından <strong>hemen aktif hale getirilir</strong>.
        </p>
        <p>
          Üyelik statüsü, kullanıcı hesabına otomatik olarak yansıtılır ve hizmet
          anında kullanılmaya başlanabilir.
        </p>
        <p>
          Satın alma işlemi sırasında sağlanan üyelik detayları ve ayrıcalıklar,
          <strong> kullanıcı panelinde görüntülenebilir</strong>.
        </p>

        <h2>İade Şartları</h2>
        <p>
          Kullanıcı, satın alma işlemi gerçekleştikten sonra <strong>24 saat içinde iade talebi</strong> yapabilir.
        </p>
        <ul>
          <li>
            İade talebi için <a href="mailto:info@tungasoft.com">info@tungasoft.com</a>{" "}
            üzerinden veya kullanıcı panelinden destek formu doldurarak başvuru
            yapılması gerekmektedir.
          </li>
          <li>
            İade talebinin kabul edilmesi için:
            <ul>
              <li>
                Abonelik hizmetinin <strong>kullanılmamış</strong> veya{" "}
                <strong>minimum düzeyde kullanılmış</strong> olması
                gerekmektedir.
              </li>
              <li>
                İade başvurusunun, satın alma tarihinden itibaren 24 saat içinde
                yapılmış olması zorunludur.
              </li>
            </ul>
          </li>
          <li>
            İade talebi onaylandığında, ödeme yapılan yöntemle ücret iadesi
            sağlanır. İade işlemleri, ortalama olarak <strong>7 iş günü</strong>{" "}
            içinde tamamlanır.
          </li>
          <li>
            <strong>Özel Not:</strong> 24 saatten sonra yapılan iade talepleri
            işleme alınamaz ve abonelik iptal edilse bile ücret iadesi yapılmaz.
          </li>
        </ul>

        <h2>Ek Açıklamalar</h2>
        <p>
          Abonelik statüsüne sahip kullanıcılar, üyelik dönemi boyunca tüm
          ayrıcalıklı hizmetlerden yararlanabilir.
        </p>
        <p>
          Kullanıcıların iade taleplerinde, satın alma işleminden itibaren geçen
          süre boyunca sağlanan hizmetler göz önünde bulundurularak değerlendirme
          yapılır.
        </p>

        <h2>Şirket Bilgileri</h2>
        <ul>
          <li>
            <strong>Şirket Adı:</strong> Tungasoft Yazılım Eğitim ve Danışmanlık
            Anonim Şirketi
          </li>
          <li>
            <strong>Adres:</strong> 75. Yıl OSB, Bilim Caddesi No: 5/107,
            Odunpazarı, Eskişehir
          </li>
          <li>
            <strong>Telefon:</strong> +90 850 305 75 62
          </li>
          <li>
            <strong>E-posta:</strong>{" "}
            <a href="mailto:info@tungasoft.com">info@tungasoft.com</a>
          </li>
          <li>
            <strong>Vergi Dairesi ve Numarası:</strong> Eskişehir V.D -{" "}
            8670892656
          </li>
          <li>
            <strong>Mersis No:</strong> 0867089265600001
          </li>
        </ul>
      </section>

      <footer className="terms-footer">
        <p>
          <strong>hocabul.com</strong>,{" "}
          <strong>Tungasoft Yazılım Eğitim ve Danışmanlık Anonim Şirketi</strong>{" "}
          tarafından geliştirilmiştir.
        </p>
      </footer>
    </div>
  );
};

export default DeliveryAndRefund;
