import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.css";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://hocabul.net/api/forgot-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        // Başarılı mesaj gösterilir ve yönlendirme yapılır
        const userConfirmed = window.confirm(
          data.message || "Kod gönderildi. E-postanızı kontrol edin.\nReset Password sayfasına gitmek ister misiniz?"
        );
        if (userConfirmed) {
          navigate("/reset-password");
        }
      } else {
        alert(data.message || "Email adresi bulunamadı.");
      }
    } catch (error) {
      console.error("Şifre sıfırlama hatası:", error);
      alert("Bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="forgot-password-page">
      <h2 className="forgot-password-title">Şifremi Unuttum</h2>
      <form onSubmit={handleForgotPassword}>
        <div className="input-group">
          <input
            type="email"
            placeholder="E-posta"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Gönderiliyor..." : "Kod Gönder"}
        </button>
      </form>
    </div>
  );
}

export default ForgotPassword;
