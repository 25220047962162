import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAdminAuthenticated: false,
    isTeacherAuthenticated: false,
  });

  useEffect(() => {
  try {
    const adminAuth = localStorage.getItem('isAdminAuthenticated') === 'true';
    const teacherAuth = localStorage.getItem('isTeacherAuthenticated') === 'true';
  

    setAuthState({
      isAdminAuthenticated: adminAuth,
      isTeacherAuthenticated: teacherAuth,
    });

    if (!adminAuth && !teacherAuth) {
      console.warn('No valid session found. Please log in again.');
    }
  } catch (error) {
    console.error('AuthContext initialization error:', error);
    setAuthState({
      isAdminAuthenticated: false,
      isTeacherAuthenticated: false,
    });
  }
}, []);


  const loginAsAdmin = (userData) => {
    try {
      setAuthState({ isAdminAuthenticated: true, isTeacherAuthenticated: false, user: userData });
      localStorage.setItem('isAdminAuthenticated', 'true');
      localStorage.removeItem('isTeacherAuthenticated');
    } catch (error) {
      console.error('Error in loginAsAdmin:', error);
    }
  };

  const loginAsTeacher = (userData) => {
    try {
      setAuthState({ isAdminAuthenticated: false, isTeacherAuthenticated: true, user: userData });
      localStorage.setItem('isTeacherAuthenticated', 'true');
      localStorage.removeItem('isAdminAuthenticated');
    } catch (error) {
      console.error('Error in loginAsTeacher:', error);
    }
  };

  const logout = () => {
    try {
      setAuthState({ isAdminAuthenticated: false, isTeacherAuthenticated: false, user: null });
      localStorage.removeItem('isAdminAuthenticated');
      localStorage.removeItem('isTeacherAuthenticated');
    } catch (error) {
      console.error('Error in logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ authState, loginAsAdmin, loginAsTeacher, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
