// src/components/ContactInfo.js
import React from 'react';
import styled from 'styled-components';
import { FaPhone, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

const ContactContainer = styled.div`
  margin-bottom: 20px;
`;

const ContactTitle = styled.h3`
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`;

const ContactItem = styled.div`
  font-size: 0.9em;
  color: #444;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Icon = styled.span`
  margin-right: 10px;
  color: #555;
`;

function ContactInfo({ phone, email, detailedAddress }) {
  return (
    <ContactContainer>
      <ContactTitle>İLETİŞİM BİLGİLERİ</ContactTitle>
      <ContactItem>
        <Icon><FaPhone /></Icon> {phone}
      </ContactItem>
      <ContactItem>
        <Icon><FaEnvelope /></Icon> {email}
      </ContactItem>
      <ContactItem>
        <Icon><FaMapMarkerAlt /></Icon> {detailedAddress}
      </ContactItem>
    </ContactContainer>
  );
}

export default ContactInfo;
