import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { AuthContext } from '../context/AuthContext';

const Header = () => {
  const { authState, logout } = useContext(AuthContext); // AuthContext'ten verileri çek
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for mobile menu
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Mobil menüyü aç/kapat
  };

  const userName = authState.user?.name || 'Kullanıcı'; // Kullanıcı adını AuthContext'ten al

  return (
    <header className="header">
      {/* Logo Section */}
      <div>
        <button
          className="logo-button"
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
          aria-label="Go to Home Page"
        >
          hocabul.net
        </button>
      </div>

      {/* Hamburger Menu for Mobile */}
      <div className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
        ☰
      </div>

      {/* Auth Section */}
      <nav className={`auth-container ${isMenuOpen ? 'open' : ''}`}>
        {authState.isTeacherAuthenticated ? ( // Kullanıcı giriş yapmışsa
          <div className="user-section">
            <span className="welcome-message">
              Hoşgeldiniz, <strong>{userName} Hocam!</strong>
            </span>
            <button
              className="profile-button"
              onClick={handleProfileClick}
              aria-label="Go to Profile"
            >
              Profilim
            </button>
            <span className="divider">|</span>
            <span className="logout" onClick={logout}>
              Çıkış Yap
            </span>
          </div>
        ) : (
          // Kullanıcı giriş yapmamışsa
          <>
            <Link to="/login" className="auth-link">
              Öğretmen Giriş Yap
            </Link>
            <span className="divider">|</span>
            <Link to="/signup" className="auth-link">
              Öğretmen Hesap Aç
            </Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
