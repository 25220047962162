import React from "react";
import "./HowItWorks.css";

const HowItWorks = () => {
  return (
    <div className="how-it-works-container">
      <h2>Nasıl Çalışır?</h2>
      <div className="steps">
        {/* Adım 1 */}
        <div className="step">
          <h3>1. Platforma Giriş</h3>
          <p>
            Kullanıcılar platforma giriş yaparak, öğretmen veya veli olarak süreçlere başlayabilir.
          </p>
        </div>

        {/* Adım 2 */}
        <div className="step">
          <h3>2. Öğretmen Süreci</h3>
          <p>
            <strong>Kayıt Ol:</strong> Öğretmenler platforma kayıt olur. <br />
            <strong>Profil Kartı Başvurusu Yap:</strong> Profil kartı oluşturup görünür hale gelmek için başvurular yapılır. <br />
            <strong>Onay Süreci:</strong> Başvuru, yönetim tarafından değerlendirilir. <br />
            <strong>Profil Kartı Listeleme:</strong> Onaylanan öğretmenlerin profil kartları, veli tarafından görüntülenebilir. <br />
            <strong>CV ve Abonelik:</strong> Daha fazla detay sunmak için CV başvurusu yapılabilir ve abonelik satın alınabilir.
          </p>
        </div>

        {/* Adım 3 */}
        <div className="step">
          <h3>3. Veli Süreci</h3>
          <p>
            <strong>Aradığınız Öğretmeni Bulun:</strong> Kategori veya uzmanlık alanına göre öğretmen arayın. <br />
            <strong>Öğretmene Ders Talebi Gönderin:</strong> Beğendiğiniz öğretmene ders talebi gönderin.
          </p>
        </div>

        {/* Adım 4 */}
        <div className="step">
          <h3>4. Ders Talebi ve Başlama</h3>
          <p>
            Öğretmen ve veli arasında yapılan anlaşma sonucunda dersler başlar.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
