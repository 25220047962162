import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { loginAsTeacher } = useContext(AuthContext);

  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

 const handleLogin = async (e) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      alert("Lütfen geçerli bir email adresi giriniz.");
      return;
    }

    if (!password) {
      alert("Lütfen bir şifre giriniz.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch("https://hocabul.net/api/login", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        alert(data.message || "Giriş başarılı.");
        loginAsTeacher({ email: data.user.email, name: data.user.name });
        navigate("/profile");
      } else {
        alert(data.message || "Email veya şifre hatalı.");
      }
    } catch (error) {
      console.error("Giriş sırasında hata:", error);
      alert("Giriş sırasında bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-page">
      <h2 className="login-title">Giriş Yap</h2>
      <div className="login-container">
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <input
              type="email"
              placeholder="E-posta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Şifre"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? "Giriş Yapılıyor..." : "Giriş Yap"}
          </button>
        </form>
		 <p>
          <a
            href="#"
            onClick={() => navigate("/forgot-password")}
            className="forgot-password-link"
          >
            Şifremi Unuttum
          </a>
        </p>
      </div>
    </div>
  );
}

export default Login;
