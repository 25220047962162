// TeacherCard Component
import React, { useEffect, useState } from 'react';
import { AiFillFileText } from 'react-icons/ai';
import { FaFileAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import './TeacherCard.css';
import AppointmentForm from '../components/AppointmentForm';

function TeacherCard({ teacher }) {
  const navigate = useNavigate(); // Initialize navigation
  const [membershipType, setMembershipType] = useState(null);
  const [isFreeLesson, setIsFreeLesson] = useState(false);
  const [totalRequests, setTotalRequests] = useState(0); // State for storing total requests
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);

  useEffect(() => {
    const fetchMembershipType = async () => {
      try {
        const response = await fetch('https://hocabul.net/api/members');
        if (!response.ok) {
          throw new Error('Failed to fetch membership data');
        }
        const members = await response.json();
        const member = members.find(
          (m) => m.email.toLowerCase() === teacher.email.toLowerCase()
        );

        if (member) {
          setMembershipType(member.membership_type);
        } else {
          console.warn(`No membership found for email: ${teacher.email}`);
        }

        setIsFreeLesson(teacher.promotion?.toLowerCase() === 'evet');
      } catch (error) {
        console.error('Error fetching membership data:', error);
      }
    };

   fetchMembershipType();
    fetchTotalRequests(); // Call fetch for total requests
  }, [teacher.email, teacher.promotion]);
  
  
   const fetchTotalRequests = async () => {
      try {
        const response = await fetch(`https://hocabul.net/api/request-count/${encodeURIComponent(teacher.email)}`);
        if (!response.ok) {
          throw new Error('Failed to fetch total requests');
        }
        const data = await response.json();
        setTotalRequests(data.count || 0); // Update state with API response or default to 0
      } catch (error) {
        console.error('Error fetching total requests:', error);
      }
    };
  
  
  
  

  const toggleModal = () => {
    setShowAppointmentForm((prevState) => !prevState);
  };

  return (
    <div className="teacher-card">
      <div className="clickable-area" onClick={() => navigate(`/cv/${teacher.email}`)}>
        <div className="image-container">
          <img
          src={teacher.photoPath ? `/uploads/${teacher.photoPath}` : '/images/default-teacher.png'}
alt={`${teacher.name} ${teacher.lastname}`}

            className="teacher-image"
          />
        </div>
		
		<div>
		<button className="talep-button">
		{totalRequests} Talep Aldım
		</button>
		</div>
		
		
		
		
		
		
		
		
        <div className="teacher-info">
          <div className="teacher-name-container">
            <h3 className="teacher-name">{`${teacher.name} ${teacher.lastname}`}</h3>
            {membershipType === 'monthly-type' && (
              <AiFillFileText className="membership-icon monthly-icon" title="Aylık Üye" />
            )}
            {membershipType === 'yearly-type' && (
              <FaFileAlt className="membership-icon yearly-icon" title="Yıllık Üye" />
            )}
          </div>
          <p className="teacher-category">{teacher.subject} dersi veriyorum</p>
        </div>
        <div className="teacher-details">
		<div className="yer">
            <span>Universite:</span>
            <strong>{teacher.university}</strong>
          </div>
		  
		  <div className="yer">
            <span>Bölüm:</span>
            <strong>{teacher.department}</strong>
          </div>	
          <div className="yer">
            <span>Yer:</span>
            <strong>{teacher.yer}</strong>
          </div>
          <div className="time">
            <span>Ders Süresi:</span>
            <strong>{teacher.time} Saat</strong>
          </div>
          <div className="format">
            <span>Ders Formatı:</span>
            <strong>{teacher.format}</strong>
          </div>
          <div className="experience">
            <span>Tecrübe:</span>
            <strong>{teacher.experience} Yıl</strong>
          </div>
          <div className="fee">
            <span>Ücret:</span>
            <strong>{teacher.fee} TL</strong>
          </div>
          <div className={`promotion ${isFreeLesson ? 'highlight' : ''}`}>
            <span>İlk Ders Ücretsiz:</span>
            <strong>{isFreeLesson ? 'Evet' : 'Hayır'}</strong>
          </div>
        </div>
      </div>
      <button className="appointment-button" onClick={toggleModal}>
        Ders Talep Et
      </button>
      {showAppointmentForm && (
        <div className="appointment-modal">
          <div className="modal-content">
            <button className="close-modal" onClick={toggleModal}>X</button>
            <AppointmentForm teacher={teacher} closeModal={toggleModal} />
          </div>
        </div>
      )}
    </div>
  );
}

export default TeacherCard;
