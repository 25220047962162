import React from "react";
import "./Terms.css"; // Temaya uygun stil için ayrı bir CSS dosyası

const Terms = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Bireysel Hesap Sözleşmesi</h1>
        <p>
          <strong>Yürürlük Tarihi:</strong> [27/11/2024]
        </p>
      </header>

      <section className="terms-content">
        <p>
          hocabul.com, <strong>Tungasoft Yazılım Eğitim ve Danışmanlık Anonim
          Şirketine</strong> ait bir ürün olup, kullanıcılarımızın platformumuzu
          kullanırken haklarını ve sorumluluklarını düzenlemek amacıyla bu
          sözleşmeyi hazırlamıştır.
        </p>

        <h2>1. Taraflar</h2>
        <p>
          Bu sözleşme, hocabul.com platformu (bundan sonra “Platform” olarak
          anılacaktır) ile bireysel hesap oluşturan kullanıcı (bundan sonra
          “Kullanıcı” olarak anılacaktır) arasında akdedilmiştir.
        </p>

        <h2>2. Sözleşmenin Amacı</h2>
        <p>
          Platforma kayıt olan kullanıcıların hak ve yükümlülüklerini, hizmetlerin
          kullanım koşullarını ve gizlilik politikasına uygun hareket edilmesini
          düzenler.
        </p>

        <h2>3. Hizmetin Kapsamı</h2>
        <p>
          Platform, özel ders almak veya vermek isteyen bireylerin bir araya
          gelmesine olanak tanıyan bir aracı hizmet sunmaktadır. Kullanıcılar,
          platformda ders talebinde bulunabilir, öğretmen profillerini inceleyebilir
          ve ders randevuları oluşturabilir.
        </p>

        <h2>4. Kullanıcının Yükümlülükleri</h2>
        <p>
          Kullanıcı, platforma kayıt olurken aşağıdaki yükümlülüklere uymayı kabul
          eder:
        </p>
        <ul>
          <li>
            4.1. Hesap oluştururken sağlanan bilgilerin doğru ve eksiksiz olduğunu
            beyan eder.
          </li>
          <li>
            4.2. Hesap güvenliği için şifresini gizli tutar ve üçüncü kişilerle
            paylaşmaz.
          </li>
          <li>
            4.3. Platformun kullanımında yasalara aykırı, yanıltıcı, zararlı veya
            uygunsuz içerikler paylaşmaz.
          </li>
          <li>
            4.4. Platform üzerinden yapılan işlemlerde diğer kullanıcıların
            haklarına saygı gösterir.
          </li>
        </ul>

        <h2>5. Platformun Hak ve Yükümlülükleri</h2>
        <ul>
          <li>
            5.1. Platform, kullanıcıların verdiği bilgilerin gizliliğini korumayı
            taahhüt eder.
          </li>
          <li>
            5.2. Platform, kullanıcıların paylaştığı bilgilerin doğruluğunu
            kontrol etmekle yükümlü değildir.
          </li>
          <li>
            5.3. Platform, hizmetlerin kesintisiz ve hatasız çalışması için gerekli
            çabayı gösterir, ancak teknik arızalar veya mücbir sebeplerden kaynaklı
            sorunlardan sorumlu tutulamaz.
          </li>
          <li>
            5.4. Platform, kullanım şartlarını ihlal eden kullanıcıların hesaplarını
            askıya alma veya silme hakkını saklı tutar.
          </li>
        </ul>

        <h2>6. Ücretlendirme ve Ödeme</h2>
        <ul>
          <li>6.1. Platforma üye olmak ücretsizdir. Ancak, belirli hizmetler için ücretli üyelik paketleri sunulabilir.</li>
          <li>6.2. Ücretli üyelik paketleri, platformda belirtilen koşullara göre satın alınır.</li>
          <li>6.3. Kullanıcı, ödeme işlemlerinde sağladığı bilgilerin doğruluğundan sorumludur.</li>
        </ul>

        <h2>7. Kişisel Verilerin Korunması</h2>
        <p>
          Kullanıcıların kişisel verileri, <strong>Kişisel Verilerin Korunması
          Politikası</strong>’na uygun şekilde işlenir ve saklanır. Kullanıcı,
          kayıt esnasında sunduğu verilerin platform tarafından işlenmesine rıza
          gösterir.
        </p>

        <h2>8. Sözleşmenin Feshi</h2>
        <p>
          Kullanıcı, hesabını istediği zaman kapatma hakkına sahiptir. Platform,
          aşağıdaki durumlarda kullanıcı hesabını askıya alabilir veya
          sonlandırabilir:
        </p>
        <ul>
          <li>Yalan beyan veya yanıltıcı bilgi verilmesi,</li>
          <li>Platformun kötüye kullanılması,</li>
          <li>Yasal düzenlemelere aykırı hareket edilmesi.</li>
        </ul>

        <h2>9. Uyuşmazlıkların Çözümü</h2>
        <p>
          Bu sözleşmeden kaynaklanan uyuşmazlıklar öncelikli olarak taraflar
          arasında çözülmeye çalışılacaktır. Çözüm sağlanamaması durumunda,
          <strong>Eskişehir Mahkemeleri ve İcra Daireleri</strong> yetkili
          olacaktır.
        </p>

        <h2>10. Yürürlük</h2>
        <p>
          Bu sözleşme, kullanıcının platforma kayıt olması ile yürürlüğe girer.
          Kullanıcı, kayıt işlemini tamamladığında bu sözleşmeyi okuduğunu,
          anladığını ve kabul ettiğini beyan eder.
        </p>
		
		
		 <h2>Şirket Bilgileri</h2>
        <ul>
          <li>
            <strong>Şirket Adı:</strong> Tungasoft Yazılım Eğitim ve Danışmanlık
            Anonim Şirketi
          </li>
          <li>
            <strong>Adres:</strong> 75. Yıl OSB, Bilim Caddesi No: 5/107,
            Odunpazarı, Eskişehir
          </li>
          <li>
            <strong>Telefon:</strong> +90 850 305 75 62
          </li>
          <li>
            <strong>E-posta:</strong>{" "}
            <a href="mailto:info@tungasoft.com">info@tungasoft.com</a>
          </li>
          <li>
            <strong>Vergi Dairesi ve Numarası:</strong> Eskişehir V.D -{" "}
            8670892656
          </li>
          <li>
            <strong>Mersis No:</strong> 0867089265600001
          </li>
        </ul>
      </section>
	

      <footer className="terms-footer">
        <p>
          <strong>hocabul.com</strong>,{" "}
          <strong>Tungasoft Yazılım Eğitim ve Danışmanlık Anonim Şirketi</strong>{" "}
          tarafından geliştirilmiştir.
        </p>
      </footer>
    </div>
  );
};

export default Terms;
