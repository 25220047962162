import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import TeacherApplication from '../pages/TeacherApplication';
import { AuthContext } from '../context/AuthContext'; // AuthContext'i import et

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #FFF;
  border-radius: 12px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const SectionTitle = styled.h2`
  font-size: 1.5em;
  color: #ffffff;
  text-align: center;
  margin-bottom: 15px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
`;

const CardButton = styled.button`
  flex: 1;
  background-color: ${({ disabled }) => (disabled ? '#888888' : '#4D9FFF')};
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#888888' : '#3a7ecc')};
  }
`;

const StatusMessage = styled.p`
  font-size: 0.9em;
  color: ${({ $status }) =>
    $status === 'approved'
      ? '#00FF00'
      : $status === 'pending'
      ? '#4D9FFF'
      : '#FF4D4D'};
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
`;

const InfoText = styled.p`
  font-size: 0.85em;
  color: #a9a9a9;
  margin-top: 5px;
  text-align: center;
`;

const UpdatePanel = styled.div`
  margin-top: 15px;
  padding: 20px;
  background-color: #3a3a4a;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
`;

const InputField = styled.input`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 1em;
`;

const UpdateButton = styled.button`
  background-color: #4d9fff;
  color: #ffffff;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3a7ecc;
  }
`;

function ProfileManagement() {
  const { authState } = useContext(AuthContext); // AuthContext'ten kullanıcı bilgilerini çek
  const [applicationStatus, setApplicationStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showApplicationForm, setShowApplicationForm] = useState(false);
  const [newPrice, setNewPrice] = useState('');
  const [showUpdatePanel, setShowUpdatePanel] = useState(false);

  const userEmail = authState.user?.email || null; // Email'i AuthContext'ten al

  useEffect(() => {
    if (userEmail) {
      fetchApplicationStatus(userEmail);
    }
  }, [userEmail]);

  const fetchApplicationStatus = async (email) => {
    try {
      const response = await fetch(
        `https://hocabul.net/api/application-status?email=${encodeURIComponent(email)}`
      );
      if (response.ok) {
        const data = await response.json();
        setApplicationStatus(data.status);
      } else {
        setApplicationStatus(null);
      }
    } catch (error) {
      console.error('Error fetching application status:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePrice = async () => {
    if (!newPrice || isNaN(newPrice)) {
      alert('Lütfen geçerli bir fiyat girin.');
      return;
    }

    try {
      const response = await fetch('https://hocabul.net/api/update-price', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail, newPrice }),
      });

      if (response.ok) {
        alert('Ders saati ücreti başarıyla güncellendi.');
        setNewPrice('');
        setShowUpdatePanel(false);
      } else {
        const errorResponse = await response.json();
        alert(errorResponse.message || 'Bir hata oluştu.');
      }
    } catch (error) {
      console.error('Error updating price:', error);
    }
  };

  const handleDeleteProfile = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/delete-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: userEmail }),
      });

      if (response.ok) {
        alert('Profil başarıyla silindi. Yeni başvuru yapabilirsiniz.');
        setApplicationStatus(null);
      } else {
        const errorResponse = await response.json();
        alert(errorResponse.message || 'Bir hata oluştu.');
      }
    } catch (error) {
      console.error('Error deleting profile:', error);
    }
  };

  if (loading) {
    return <p>Yükleniyor...</p>;
  }

  if (showApplicationForm) {
    return <TeacherApplication />;
  }

  return (
    <ProfileContainer>
      <SectionTitle>Profil Yönetimi</SectionTitle>

      <ButtonGroup>
        <CardButton
          disabled={applicationStatus === 'approved' || applicationStatus === 'pending'}
          onClick={() => setShowApplicationForm(true)}
        >
          Başvuru Yap
        </CardButton>

        <CardButton
          disabled={applicationStatus !== 'approved'}
          onClick={() => setShowUpdatePanel(!showUpdatePanel)}
        >
          Ücreti Güncelle
        </CardButton>

        <CardButton
          disabled={applicationStatus !== 'approved'}
          onClick={handleDeleteProfile}
        >
          Profil Sil
        </CardButton>
      </ButtonGroup>

      {showUpdatePanel && (
        <UpdatePanel>
          <h3>Yeni Ders Ücreti</h3>
          <InputField
            type="number"
            value={newPrice}
            onChange={(e) => setNewPrice(e.target.value)}
            placeholder="Ücret girin"
          />
          <UpdateButton onClick={handleUpdatePrice}>Güncelle</UpdateButton>
        </UpdatePanel>
      )}

      <StatusMessage $status={applicationStatus}>
        {applicationStatus === 'approved'
          ? 'Başvurunuz Onaylandı'
          : applicationStatus === 'pending'
          ? 'Başvurunuz Değerlendiriliyor'
          : 'Başvuru Bulunamadı'}
      </StatusMessage>

      <InfoText>Profiliniz 30 gün boyunca ücretsiz olarak listelenir.</InfoText>
    </ProfileContainer>
  );
}

export default ProfileManagement;
