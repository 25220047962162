import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ResetPassword.css";

// Bu sayfa token yerine code ile çalışıyor.
// Kullanıcı emailini ForgotPassword sayfasında girdi, emailine kod geldi.
// Burada kodu ve yeni şifreyi giriyor.

function ResetPassword() {
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch("https://hocabul.net/api/reset-password", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ code, password }),
      });

      const data = await response.json();
      if (response.ok) {
        alert(data.message || "Şifre başarıyla sıfırlandı.");
        navigate("/login");
      } else {
        alert(data.message || "Şifre sıfırlama başarısız.");
      }
    } catch (error) {
      console.error("Şifre sıfırlama hatası:", error);
      alert("Bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-password-page">
      <h2 className="reset-password-title">Kodu Gir ve Şifreyi Sıfırla</h2>
      <form onSubmit={handleResetPassword}>
        <div className="input-group">
          <input
            type="text"
            placeholder="E-postanıza gelen kod"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
        </div>
        <div className="input-group">
          <input
            type="password"
            placeholder="Yeni Şifre"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Sıfırlanıyor..." : "Şifreyi Sıfırla"}
        </button>
      </form>
    </div>
  );
}

export default ResetPassword;
