// src/pages/AdminDashboard.js
import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';
import TeacherCard from '../pages/TeacherCard';
import ContentManagement from './ContentManagement';
import MembershipUpdate from './MembershipUpdate';



function AdminDashboard() {
  const [activeTab, setActiveTab] = useState('dashboard');
  const [totalTeachers, setTotalTeachers] = useState(0);
  const [cityStatistics, setCityStatistics] = useState([]);
  const [applications, setApplications] = useState([]);
  const [prices, setPrices] = useState({ free: 0, monthly: 0, yearly: 0 });
  const [totalRequests, setTotalRequests] = useState(0);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
   const [membershipCounts, setMembershipCounts] = useState({
    freeType: 0,
    monthlyType: 0,
    yearlyType: 0,
  }); // Üyelik tipleri için state

  // Fetch teacher stats when component loads
  useEffect(() => {
    fetchTeacherStats();
	fetchTotalRequests(); // Toplam talep sayısını fetch et
	fetchPrices(); // Fiyatları çek
	 fetchMembershipCounts(); // Üyelik sayılarını fetch et
  }, []);




const fetchPrices = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/prices');
      if (response.ok) {
        const data = await response.json();
        setPrices(data);
      } else {
        console.error('Fiyat bilgileri alınamadı');
      }
    } catch (error) {
      console.error('Fiyatları alırken hata oluştu:', error.message);
    }
  };

  const updatePrices = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('https://hocabul.net/api/prices', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(prices),
      });
      if (response.ok) {
        alert('Fiyatlar başarıyla güncellendi.');
        fetchPrices();
      } else {
        console.error('Fiyatlar güncellenemedi');
      }
    } catch (error) {
      console.error('Fiyatları güncellerken hata oluştu:', error.message);
    }
  };


 // Toplam talep sayısını fetch eden fonksiyon
  const fetchTotalRequests = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/total-request-count');
      if (!response.ok) throw new Error('Failed to fetch total requests');
      const data = await response.json();
      setTotalRequests(data.total); // Gelen toplam sayıyı state'e ata
    } catch (error) {
      console.error('Error fetching total requests:', error);
    }
  };


 // Üyelik sayılarını fetch eden fonksiyon
  const fetchMembershipCounts = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/membership-counts');
      if (!response.ok) throw new Error('Failed to fetch membership counts');
      const data = await response.json();
      setMembershipCounts(data);
    } catch (error) {
      console.error('Error fetching membership counts:', error);
    }
  };


  // Fetch pending applications when the "applications" tab is active
  useEffect(() => {
    if (activeTab === 'applications') {
      fetchPendingApplications();
    }
  }, [activeTab]);

  const fetchTeacherStats = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/teacher-stats');
      if (!response.ok) throw new Error('Failed to fetch teacher stats');
      const data = await response.json();
      setTotalTeachers(data.totalTeachers);

      const cityStatsArray = Object.entries(data.cityCounts).map(([city, count]) => ({
        city,
        count,
      }));
      setCityStatistics(cityStatsArray);
    } catch (error) {
      console.error('Error fetching teacher stats:', error);
    }
  };

  const fetchPendingApplications = async () => {
    try {
      const response = await fetch('https://hocabul.net/api/pending-applications');
      if (!response.ok) throw new Error('Failed to fetch pending applications');
      const data = await response.json();
      setApplications(data);
    } catch (error) {
      console.error('Error fetching pending applications:', error);
    }
  };

  const handleApprove = async (id) => {
    try {
      await fetch(`https://hocabul.net/api/approve-application/${id}`, { method: 'POST' });
      fetchPendingApplications();
    } catch (error) {
      console.error('Error approving application:', error);
    }
  };

  const handleReject = async (id) => {
    try {
      await fetch(`https://hocabul.net/api/reject-application/${id}`, { method: 'POST' });
      fetchPendingApplications();
    } catch (error) {
      console.error('Error rejecting application:', error);
    }
  };

 const handleViewCard = (application) => {
  // Artık imageUrl oluşturma işlemine gerek yok

  console.log('Selected Application:', application); // Seçilen başvuruyu kontrol et

  // `application` nesnesini doğrudan duruma atıyoruz
  setSelectedApplication(application);
  setIsModalOpen(true);
};


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedApplication(null);
  };

  

  

  return (
    <div className="admin-dashboard">
      <div className="sidebar">
        <div
          className={`sidebar-item ${activeTab === 'dashboard' ? 'active' : ''}`}
          onClick={() => setActiveTab('dashboard')}
        >
          🏠 Anasayfa
        </div>
        <div
          className={`sidebar-item ${activeTab === 'applications' ? 'active' : ''}`}
          onClick={() => setActiveTab('applications')}
        >
          👤 Öğretmen Başvuruları
        </div>
        <div
          className={`sidebar-item ${activeTab === 'statistics' ? 'active' : ''}`}
          onClick={() => setActiveTab('statistics')}
        >
          📊 Şehir Bazlı İstatistikler
       </div>
        <div
          className={`sidebar-item ${activeTab === 'price-update' ? 'active' : ''}`}
          onClick={() => setActiveTab('price-update')}
        >
          💲 Fiyat Güncelleme
        </div>
		
		<div
    className={`sidebar-item ${activeTab === 'content-update' ? 'active' : ''}`}
    onClick={() => setActiveTab('content-update')}
  >
    ✏️ İçerik Güncelleme
  </div>

		
<div
  className={`sidebar-item ${activeTab === 'membership-update' ? 'active' : ''}`}
  onClick={() => setActiveTab('membership-update')}
>
  🛠 Üyelik Yükseltme
</div>

		
		
      </div>
      <div className="content">
       

        {activeTab === 'dashboard' && (
          <>
            <h2>Performans Metrikleri</h2>
            <div className="metrics">
             
              <div className="metric-card">
                <span>Talep Sayısı</span>
                <strong> {totalRequests}  </strong>
              </div>
              <div className="metric-card">
                <span>Toplam Öğretmen Sayısı</span>
                <strong>{totalTeachers}</strong>
              </div>
              <div className="metric-card">
                <span>Toplam Satış</span>
                <strong>267.000₺</strong>
              </div>
			  <div className="metric-card">
                <span>Ücretsiz Paketler</span>
                <strong>{membershipCounts.freeType}</strong>
              </div>
			  <div className="metric-card">
                <span>Aylık Paketler</span>
                <strong>{membershipCounts.monthlyType}</strong>
              </div>
			  <div className="metric-card">
                <span>Yıllık Paketler</span>
                <strong>{membershipCounts.yearlyType}</strong>
              </div>
			  
            </div>
          </>
        )}



        {activeTab === 'applications' && (
          <div className="applications">
            <h2>Öğretmen Başvuruları</h2>
            <table className="applications-table">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Ad</th>
                  <th>İşlem</th>
                </tr>
              </thead>
              <tbody>
                {applications.map((application) => (
                  <tr key={application.id}>
                    <td>{application.id}</td>
                    <td>
                      {application.name} {application.lastname}
                    </td>
                    <td className="action-buttons">
                      <button
                        className="view-btn"
                        onClick={() => handleViewCard(application)}
                      >
                        Kart Görüntüle
                      </button>
                      <button
                        className="approve-btn"
                        onClick={() => handleApprove(application.id)}
                      >
                        Onayla
                      </button>
                      <button
                        className="reject-btn"
                        onClick={() => handleReject(application.id)}
                      >
                        Reddet
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}






 {activeTab === 'price-update' && (
          <div className="metric-card">
            <h2>Fiyat Güncelleme</h2>
            <form onSubmit={updatePrices}>   
              <div>
                <label>Aylık Paket:</label>
                <input
                  type="number"
                  value={prices.monthly}
                  onChange={(e) => setPrices({ ...prices, monthly: parseInt(e.target.value) })}
                />
              </div>
              <div>
                <label>Yıllık Paket:</label>
                <input
                  type="number"
                  value={prices.yearly}
                  onChange={(e) => setPrices({ ...prices, yearly: parseInt(e.target.value) })}
                />
              </div>
              <button 
			  className="approve-btn"
			  type="submit">Fiyatları Güncelle</button>
            </form>
          </div>
        )}
		
		
		
		{activeTab === 'content-update' && (
    <div className="content-update">
      <h2>İçerik Güncelleme</h2>
      <ContentManagement />
    </div>
  )}


{activeTab === 'membership-update' && (
  <div className="membership-update-container">
    <MembershipUpdate />
  </div>
)}



        {activeTab === 'statistics' && (
          <>
            <h2>İl Tabanlı Öğretmen Sayıları</h2>
            <div className="city-metrics">
              {cityStatistics.map((stat, index) => (
                <div key={index} className="metric-card">
                  <span>{stat.city}</span>
                  <strong>{stat.count}</strong>
                </div>
              ))}
            </div>
          </>
        )}

        {isModalOpen && selectedApplication && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="close-btn" onClick={closeModal}>
                &times;
              </button>
              <TeacherCard teacher={selectedApplication} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default AdminDashboard;
