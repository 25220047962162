import React from "react";
import "./Terms.css"; // Tasarım için mevcut CSS dosyasını kullanıyoruz

const SalesContract = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Mesafeli Satış Sözleşmesi</h1>
        <p>
          <strong>Yürürlük Tarihi:</strong> [27/11/2024]
        </p>
      </header>

      <section className="terms-content">
        <p>
          İşbu sözleşme, hocabul.com platformunda sunulan hizmetlerin satışı ile
          ilgili olarak, 6502 sayılı Tüketicinin Korunması Hakkında Kanun ve
          Mesafeli Sözleşmelere Dair Yönetmelik hükümleri gereğince, aşağıdaki
          şartlar dahilinde alıcı ve satıcı arasında akdedilmiştir.
        </p>

        <h2>1. Taraflar</h2>
        <p>
          <strong>Satıcı:</strong> Tungasoft Yazılım Eğitim ve Danışmanlık Anonim
          Şirketi
        </p>
        
        <p>
          <strong>Alıcı:</strong> Kullanıcı tarafından platforma kayıt sırasında
          sağlanan bilgiler
        </p>

        <h2>2. Sözleşmenin Konusu</h2>
        <p>
          İşbu sözleşmenin konusu, alıcı tarafından satın alınan abonelik
          paketleri ve hizmetlerin sunumuna ilişkin hak ve yükümlülükleri
          düzenler.
        </p>

        <h2>3. Hizmetin Kapsamı ve Paketler</h2>
        <p>
          Satıcı, alıcıya aşağıda belirtilen abonelik paketlerini ve bu paketlerin
          sağladığı özellikleri sunar. Alıcı, seçtiği pakete göre bu
          özelliklerden yararlanabilir.
        </p>
        <ul>
          <li>
            <strong>Tanışma Paketi:</strong> Ücretsiz olarak sunulur ve yalnızca
            profil listeleme özelliğini içerir. Süre: 1 ay.
          </li>
          <li>
            <strong>Aylık Abonelik Paketi:</strong> CV listeleme ve CV'de adres/telefon ekleme gibi
            özellikleri içerir. Süre: 1 ay.
          </li>
          <li>
            <strong>Yıllık Abonelik Paketi:</strong> Profil listeleme, CV düzenleme, CV'de
            sosyal medya hesapları, YouTube videosu ekleme, etkili CV
            danışmanlığı vb. Süre: 1 yıl.
          </li>
        </ul>

        <h2>4. Fiyatlar ve Ödeme</h2>
        <ul>
          <li>
            Alıcı, Satıcı’nın sunduğu hizmetlerden, web sitesinde belirtilen
            fiyatlandırmaya uygun şekilde faydalanabilir.
          </li>
          <li>
            Ödeme işlemleri, güvenli bir şekilde Satıcı tarafından belirtilen
            ödeme altyapıları aracılığıyla gerçekleştirilir.
          </li>
          <li>
            Satıcı, fiyatlarda değişiklik yapma hakkını saklı tutar. Ancak, alıcı
            tarafından satın alınmış hizmetin fiyatı değiştirilemez.
          </li>
        </ul>

        <h2>5. Teslimat ve Kullanım</h2>
        <p>
          Abonelik paketleri, ödemenin tamamlanmasının ardından otomatik olarak
          alıcının hesabına tanımlanır ve anında kullanıma açılır.
        </p>

        <h2>6. Cayma Hakkı ve İade</h2>
        <ul>
          <li>
            Alıcı, satın alma işlemini gerçekleştirdikten sonra 24 saat içinde
            cayma hakkını kullanabilir.
          </li>
          <li>
            Cayma hakkı talepleri,{" "}
            <a href="mailto:info@tungasoft.com">info@tungasoft.com</a> adresine e-posta
            yoluyla veya web sitesi üzerindeki destek paneli üzerinden
            iletilmelidir.
          </li>
          <li>
            İade işlemleri, iade talebinin onaylanmasının ardından 7 iş günü
            içerisinde alıcının ödediği yöntemle gerçekleştirilir.
          </li>
        </ul>

        <h2>7. Kişisel Verilerin Korunması</h2>
        <p>
          Alıcı tarafından sağlanan kişisel veriler, 6698 sayılı Kişisel Verilerin
          Korunması Kanunu kapsamında işlenir ve saklanır.
        </p>

        <h2>8. Uyuşmazlıkların Çözümü</h2>
        <p>
          İşbu sözleşmeden doğan uyuşmazlıklar öncelikle taraflar arasında
          çözülmeye çalışılır. Çözüm sağlanamaması durumunda, Eskişehir
          Mahkemeleri ve İcra Daireleri yetkilidir.
        </p>

        <h2>9. Yürürlük</h2>
        <p>
          Bu sözleşme, alıcının platformda bir hizmet satın almasıyla yürürlüğe
          girer. Alıcı, satın alma işlemini tamamlayarak işbu sözleşmenin tüm
          koşullarını kabul etmiş sayılır.
        </p>
		
		 <h2>Şirket Bilgileri</h2>
        <ul>
          <li>
            <strong>Şirket Adı:</strong> Tungasoft Yazılım Eğitim ve Danışmanlık
            Anonim Şirketi
          </li>
          <li>
            <strong>Adres:</strong> 75. Yıl OSB, Bilim Caddesi No: 5/107,
            Odunpazarı, Eskişehir
          </li>
          <li>
            <strong>Telefon:</strong> +90 850 305 75 62
          </li>
          <li>
            <strong>E-posta:</strong>{" "}
            <a href="mailto:info@tungasoft.com">info@tungasoft.com</a>
          </li>
          <li>
            <strong>Vergi Dairesi ve Numarası:</strong> Eskişehir V.D -{" "}
            8670892656
          </li>
          <li>
            <strong>Mersis No:</strong> 0867089265600001
          </li>
        </ul>
    
      </section>

      <footer className="terms-footer">
        <p>
          <strong>hocabul.com</strong>, Tungasoft Yazılım Eğitim ve Danışmanlık
          Anonim Şirketi tarafından geliştirilmiştir.
        </p>
      </footer>
    </div>
  );
};

export default SalesContract;
